<template>
  <SettingsDetails :title="$t('settings.chat.renameGroup.title')">
    <div class="details-content">
      <Form @submit-form="handleSubmit">
        <div class="container">
          <div class="content">
            <Input
              v-model="nameValue"
              :name="FormFieldNameEnum.Name"
              label="settings.chat.renameGroup.groupName"
              required
            />
          </div>
          <GenericButton
            :variant="GenericButtonVariantEnum.Primary"
            text="settings.personalDetailsContent.saveDetails"
          />
        </div>
      </Form>
    </div>
  </SettingsDetails>
</template>
<script setup lang="ts">
import SettingsDetails from "@/components/settings/SettingsDetails/SettingsDetails.vue";
import Form from "@/components/layouts/Form.vue";
import GenericButton from "@/components/buttons/GenericButton.vue";
import { ActionTypes } from "@/store";
import { Chat } from "@/store/chats/types";
import { FormFieldNameEnum } from "@/types/form";
import { computed, ref } from "vue";
import { useGetChatSettingsCommonProperties } from "../useGetChatSettingsCommonProperties";
import { GenericButtonVariantEnum } from "@/types/button";
import Input from "@/components/forms/Input.vue";

const { selectedChatId, route, router, dispatch, getters } =
  useGetChatSettingsCommonProperties();

const chatId = computed(() => {
  return selectedChatId.value ?? (route.params.chat_id as string);
});
const chatDetail = computed<Chat>(() => {
  return getters.GET_CONVERTED_CHAT_DETAIL(chatId.value);
});

const name = computed(() => chatDetail.value.name ?? "");

const nameValue = ref(name.value);

const handleSubmit = async () => {
  await dispatch(ActionTypes.UPDATE_GROUP_CHAT, {
    chatId: chatId.value,
    name: nameValue.value,
  });

  router.back();
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/settings/settingsPersonalDetails";
</style>
