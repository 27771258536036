<template>
  <MobileLayout v-if="forwardedMessage && isMobile">
    <ForwardMessageSelect />
  </MobileLayout>
  <div
    v-if="!forwardedMessage"
    ref="root"
    class="chat-wrapper"
    :class="{ isBlocking: isBlockingTextShown }"
    :style="style"
  >
    <ChatTopBar />
    <ChatContent :messages="messages" />
    <ChatInputBar v-if="!isBlockingTextShown" :members="members" />
  </div>
</template>

<script setup lang="ts">
import ChatTopBar from "@/components/chat/chatContainer/ChatTopBar/ChatTopBar.vue";
import ChatInputBar from "@/components/chat/chatContainer/ChatInputBar/ChatInputBar.vue";
import ChatContent from "@/components/chat/chatContainer/ChatContent/ChatContent.vue";
import { useGetChatContentContainerProperties } from "./useGetChatContentContainerProperties";
import { computed } from "vue";
import { GetterTypes } from "@/store";
import MobileLayout from "@/components/layouts/MobileLayout.vue";
import ForwardMessageSelect from "../ForwardMessageSelect/ForwardMessageSelect.vue";
import { useComputedValue, useGetMessageHandlers } from "@/composables";
import { AppEnvironment, LayoutTypes } from "@/store/app/state";

const { forwardedMessage } = useGetMessageHandlers();

const environment = useComputedValue<AppEnvironment>(
  GetterTypes.GET_APP_ENVIRONMENT,
);
const layoutType = useComputedValue<LayoutTypes>(GetterTypes.GET_LAYOUT_TYPE);
const isMobile = computed(
  () =>
    layoutType.value === LayoutTypes.MOBILE && environment.value.isMobileApp,
);
const { root, style, isBlockingTextShown, messages, members } =
  useGetChatContentContainerProperties();
</script>

<style lang="scss" scoped>
@import "src/assets/scss/chat/chatContainer/chatContainer.scss";
</style>
