import { createRouter, createWebHistory } from "vue-router";
import Chat from "../views/Chat.vue";
import NewChat from "../views/NewChat.vue";
import CommonView from "../views/CommonView.vue";
import LoginEmail from "../components/login/LoginEmail.vue";
import LoginPhone from "../components/login/LoginPhone/LoginPhone.vue";
import RegisterForm from "../components/register/RegisterForm.vue";
import WelcomePage from "../components/welcome/WelcomePage.vue";
import WelcomeNotifications from "../components/welcome/WelcomeNotifications.vue";
import WelcomeTransferContacts from "../components/welcome/WelcomeTransferContacts.vue";
import OnboardingWelcome from "../components/onboarding/OnboardingWelcome.vue";
import RestorePassword from "../views/RestorePassword.vue";
import RestorePasswordSend from "../components/restore/RestorePasswordSend.vue";
import RestorePasswordChange from "../components/restore/RestorePasswordChange.vue";
import {
  getIsAuthenticatedAsync,
  logoutUser,
} from "@/services/maitrejaApi/auth";

import ChatSettings from "../views/ChatSettings.vue";
import SettingsAll from "../components/settings/SettingsAll.vue";
import ChatSettingsAll from "../components/chat/chatSettings/ChatSettingsAll/ChatSettingsAll.vue";
import ChatSettingsReportUser from "../components/chat/chatSettings/ChatSettingsReportUser/ChatSettingsReportUser.vue";
import ChatSettingsBlockUser from "../components/chat/chatSettings/ChatSettingsBlockUser/ChatSettingsBlockUser.vue";
import ChatSettingsUnblockUser from "../components/chat/chatSettings/ChatSettingsUnblockUser/ChatSettingsUnblockUser.vue";
import ChatSettingsDeleteChat from "../components/chat/chatSettings/ChatSettingsDeleteChat/ChatSettingsDeleteChat.vue";
import ChatSettingsLeaveGroupConversation from "../components/chat/chatSettings/ChatSettingsLeaveGroupConversation/ChatSettingsLeaveGroupConversation.vue";
import ChatSettingsRenameGroupConversation from "../components/chat/chatSettings/ChatSettingsRenameGroupConversation/ChatSettingsRenameGroupConversation.vue";
import ChatSettingsShowGroupUsers from "../components/chat/chatSettings/ChatSettingsShowGroupUsers/ChatSettingsShowGroupUsers.vue";
import NewChatMobileGroupChat from "../components/chat/chatContainer/NewChatMobileGroupChat.vue";
import EditChatMobileGroupChat from "../components/chat/chatContainer/EditChatMobileGroupChat.vue";
import SettingsPersonalDetails from "../components/settings/SettingsPersonalDetails.vue";
import SettingsActivateAccount from "../components/settings/SettingsActivateAccount.vue";
import SettingsConnectPhoneNumber from "../components/settings/SettingsConnectPhoneNumber.vue";
import SettingsNotifications from "../components/settings/SettingsNotifications.vue";
import SettingsTermsAndConditions from "../components/settings/SettingsTermsAndConditions.vue";
import SettingsDeleteAccount from "../components/settings/SettingsDeleteAccount/SettingsDeleteAccount.vue";
import SettingsChangeLanguage from "../components/settings/SettingsChangeLanguage.vue";
import SettingsTransferContacts from "../components/settings/SettingsTransferContacts.vue";
import { LayoutBackgroundColorTypes } from "@/store/app/state";
import { handleMountErrorMessageSnackbar } from "@/utils/app";
import { loginEmailSnackbarErrorObject } from "@/utils/auth";
import store, { MutationTypes } from "@/store";
import ShareView from "@/views/ShareView.vue";

const routes = [
  {
    path: "/",
    name: "Chat",
    component: Chat,
    beforeEnter: async () => {
      try {
        store.commit(MutationTypes.SET_IS_LOADING, true);
        const isAuthenticated = await getIsAuthenticatedAsync();
        store.commit(MutationTypes.SET_IS_LOADING, false);

        if (!isAuthenticated) {
          return { name: "WelcomePage" };
        }
      } catch (error) {
        handleMountErrorMessageSnackbar(error, loginEmailSnackbarErrorObject);
        return { name: "Logout" };
      }
    },
    props: {
      topBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
      bottomBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
    },
    children: [
      {
        path: ":chat_id",
        name: "SelectedChat",
        component: Chat,
        props: {
          topBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
          bottomBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
        },
      },
    ],
  },
  {
    path: "/settings",
    name: "Settings",
    component: CommonView,
    beforeEnter: async () => {
      try {
        const isAuthenticated = await getIsAuthenticatedAsync(true);
        if (!isAuthenticated) {
          return { name: "WelcomePage" };
        }
      } catch (error) {
        handleMountErrorMessageSnackbar(error, loginEmailSnackbarErrorObject);
        return { name: "Logout" };
      }
    },
    children: [
      {
        path: "",
        name: "SettingsAll",
        component: SettingsAll,
        props: {
          topBackgroundColorType: LayoutBackgroundColorTypes.GRAY,
          bottomBackgroundColorType: LayoutBackgroundColorTypes.GRAY,
        },
      },
      {
        path: "personal-details",
        name: "SettingsPersonalDetails",
        component: SettingsPersonalDetails,
        props: {
          topBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
          bottomBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
        },
      },
      {
        path: "activate-account",
        name: "SettingsActivateAccount",
        component: SettingsActivateAccount,
        props: {
          topBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
          bottomBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
        },
      },
      {
        path: "connect-phone-number",
        name: "SettingsConnectPhoneNumber",
        component: SettingsConnectPhoneNumber,
        props: {
          topBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
          bottomBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
        },
      },
      {
        path: "notifications",
        name: "SettingsNotifications",
        component: SettingsNotifications,
        props: {
          topBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
          bottomBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
        },
      },
      {
        path: "delete-account",
        name: "SettingsDeleteAccount",
        component: SettingsDeleteAccount,
        props: {
          topBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
          bottomBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
        },
      },
      {
        path: "language",
        name: "SettingsChangeLanguage",
        component: SettingsChangeLanguage,
        props: {
          topBackgroundColorType: LayoutBackgroundColorTypes.GRAY,
          bottomBackgroundColorType: LayoutBackgroundColorTypes.GRAY,
        },
      },
      {
        path: "terms",
        name: "SettingsTermsAndConditions",
        component: SettingsTermsAndConditions,
        props: {
          topBackgroundColorType: LayoutBackgroundColorTypes.GRAY,
          bottomBackgroundColorType: LayoutBackgroundColorTypes.GRAY,
        },
      },
      {
        path: "transfer-contacts",
        name: "SettingsTransferContacts",
        component: SettingsTransferContacts,
        props: {
          topBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
          bottomBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
        },
      },
      // {
      //   path: "profile-picture",
      //   name: "SettingsProfilePicture",
      //   component: SettingsProfilePicture,
      //   props: {
      //     topBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
      //  bottomBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
      //   },
      // },
      {
        path: "chat",
        name: "ChatSettings",
        component: ChatSettings,
        props: {
          topBackgroundColorType: LayoutBackgroundColorTypes.GRAY,
          bottomBackgroundColorType: LayoutBackgroundColorTypes.GRAY,
        },
        children: [
          {
            path: "",
            name: "ChatSettingsAll",
            component: ChatSettingsAll,
            props: {
              topBackgroundColorType: LayoutBackgroundColorTypes.GRAY,
              bottomBackgroundColorType: LayoutBackgroundColorTypes.GRAY,
            },
          },
          {
            path: "report_user/:chat_id",
            name: "ChatSettingsReportUser",
            component: ChatSettingsReportUser,
            props: {
              topBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
              bottomBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
            },
          },
          {
            path: "block_user/:chat_id",
            name: "ChatSettingsBlockUser",
            component: ChatSettingsBlockUser,
            props: {
              topBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
              bottomBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
            },
          },
          {
            path: "unblock_user/:chat_id",
            name: "ChatSettingsUnblockUser",
            component: ChatSettingsUnblockUser,
            props: {
              topBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
              bottomBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
            },
          },
          {
            path: "delete_chat/:chat_id",
            name: "ChatSettingsDeleteChat",
            component: ChatSettingsDeleteChat,
            props: {
              topBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
              bottomBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
            },
          },
          {
            path: "leave_group_conversation/:chat_id",
            name: "ChatSettingsLeaveGroupConversation",
            component: ChatSettingsLeaveGroupConversation,
            props: {
              topBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
              bottomBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
            },
          },
          {
            path: "rename_group/:chat_id",
            name: "ChatSettingsRenameGroupConversation",
            component: ChatSettingsRenameGroupConversation,
            props: {
              topBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
              bottomBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
            },
          },
          {
            path: "show_group_users/:chat_id",
            name: "ChatSettingsShowGroupUsers",
            component: ChatSettingsShowGroupUsers,
            props: {
              topBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
              bottomBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
            },
          },
          {
            path: ":chat_id",
            name: "ChatSettingsAll",
            component: ChatSettingsAll,
            props: {
              topBackgroundColorType: LayoutBackgroundColorTypes.GRAY,
              bottomBackgroundColorType: LayoutBackgroundColorTypes.GRAY,
            },
          },
          {
            path: "create_group_chat/:chat_id",
            name: "NewChatMobileGroupChat",
            component: NewChatMobileGroupChat,
            props: {
              topBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
              bottomBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
            },
          },
          {
            path: "add_users_to_group/:chat_id",
            name: "EditChatMobileGroupChat",
            component: EditChatMobileGroupChat,
            props: {
              topBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
              bottomBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/new",
    name: "NewChat",
    component: NewChat,
    beforeEnter: async () => {
      try {
        const isAuthenticated = await getIsAuthenticatedAsync();
        if (!isAuthenticated) {
          return { name: "WelcomePage" };
        }
      } catch (error) {
        handleMountErrorMessageSnackbar(error, loginEmailSnackbarErrorObject);
        return { name: "Logout" };
      }
    },
  },
  {
    path: "/login",
    name: "Login",
    component: CommonView,
    children: [
      {
        path: "email",
        name: "LoginEmail",
        component: LoginEmail,
        props: {
          topBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
          bottomBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
        },
      },
      {
        path: "phone",
        name: "LoginPhone",
        component: LoginPhone,
      },
    ],
    beforeEnter: async () => {
      const isAuth = await getIsAuthenticatedAsync();

      if (isAuth) {
        return { name: "Chat" };
      }
    },
  },
  {
    path: "/register",
    name: "Register",
    component: CommonView,
    children: [
      {
        path: "",
        name: "RegisterForm",
        component: RegisterForm,
        props: {
          topBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
          bottomBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
        },
        beforeEnter: async () => {
          const isAuth = await getIsAuthenticatedAsync();

          if (isAuth) {
            return { name: "Chat" };
          }
        },
      },
      {
        path: "welcome",
        name: "OnboardingWelcome",
        component: OnboardingWelcome,
      },
      {
        path: "notifications",
        name: "WelcomeNotifications",
        component: WelcomeNotifications,
        props: {
          topBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
          bottomBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
        },
      },
      {
        path: "transfer-contacts",
        name: "WelcomeTransferContacts",
        component: WelcomeTransferContacts,
        props: {
          topBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
          bottomBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
        },
      },
    ],
  },
  {
    path: "/restore",
    name: "RestorePassword",
    component: RestorePassword,
    children: [
      {
        path: "",
        name: "RestorePasswordSend",
        component: RestorePasswordSend,
      },
      {
        path: ":chat_id",
        name: "RestorePasswordChange",
        component: RestorePasswordChange,
      },
    ],
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: CommonView,
    children: [
      {
        path: "",
        name: "WelcomePage",
        component: WelcomePage,
        props: {
          topBackgroundColorType: LayoutBackgroundColorTypes.ORANGE,
          bottomBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
        },
      },
    ],
    props: {
      topBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
      bottomBackgroundColorType: LayoutBackgroundColorTypes.WHITE,
    },
  },
  {
    path: "/logout",
    name: "Logout",
    component: {
      beforeRouteEnter: () => {
        logoutUser();
      },
    },
  },
  {
    path: "/share",
    name: "Share",
    component: ShareView,
    beforeEnter: async () => {
      try {
        store.commit(MutationTypes.SET_IS_LOADING, true);
        const isAuthenticated = await getIsAuthenticatedAsync();
        store.commit(MutationTypes.SET_IS_LOADING, false);
        if (!isAuthenticated) {
          return { name: "WelcomePage" };
        }
      } catch (error) {
        handleMountErrorMessageSnackbar(error, loginEmailSnackbarErrorObject);
        return { name: "Logout" };
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
