import { chatApiAxios } from "@/plugins";
import { HttpRequestMethodEnum } from "@/types/api";
import { IForwardMessage, IForwardMessageResponse } from "@/types/chat";
import { config } from "@/config";
import { getCommonJsonHeaders } from "@/utils/api";

export const forwardMessageBeAsync = async ({
  items,
  messageText,
  bearerToken,
  messageType,
}: IForwardMessage) => {
  const res = await chatApiAxios<IForwardMessageResponse>(
    config.api.chatApi.endpoints.message.forwardMessage,
    {
      method: HttpRequestMethodEnum.POST,
      headers: getCommonJsonHeaders(bearerToken),
      data: {
        items,
        messageText,
        messageType,
      },
    },
  );

  return res.data.failedIdentifiers;
};
