import { config } from "@/config";
import { chatApiAxios } from "@/plugins";
import { HttpRequestMethodEnum } from "@/types/api";
import { ISendMessage } from "@/types/chat";
import { getCommonJsonHeaders } from "@/utils/api";

export const sendMessageBeAsync = async ({
  chatId,
  messageText,
  messageId,
  bearerToken,
  attachments,
  messageType,
  replyToMessageId,
  modifyingMessageId,
}: ISendMessage) => {
  const res = await chatApiAxios(config.api.chatApi.endpoints.message.index, {
    method: HttpRequestMethodEnum.POST,
    headers: getCommonJsonHeaders(bearerToken),
    data: {
      chatId,
      messageId,
      messageText,
      attachments,
      messageType,
      replyToMessageId,
      modifyingMessageId,
    },
  });

  return res.data;
};
