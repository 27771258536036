<template>
  <div class="popup edit">
    <div class="popup-header spacer">
      <span>{{ title }}</span>
      <span
        v-if="actionTitle && !!action"
        class="highlight hover"
        @click="action"
        >{{ actionTitle }}</span
      >
    </div>
    <div class="divider" />
    <div class="popup-content small-padding">
      <div class="members">
        <div v-if="isAddMembersShown" class="member">
          <Icon :icon="IconType.DEFAULT_SINGLE_GRAY" class="picture" />
          <UsersSelectBox @set-selected-users="addMember" />
        </div>
        <div v-for="member in members" :key="member.id" class="member">
          <ProfileImage :id="member.id" :image="member.picture" :size="54" />
          <span class="name">{{ member.name }}</span>
          <Icon
            :icon="itemIcon"
            class="icon"
            @click="handleItemAction(member)"
          />
        </div>
      </div>
    </div>
    <div class="divider" />
    <div class="popup-footer">
      <PopupCancelButton />
      <button class="btn btn-primary" @click="handleConfirm">
        {{ confirmTitle }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import PopupCancelButton from "@/components/popup/PopupCancelButton.vue";
import UsersSelectBox from "@/components/select/UsersSelectBox.vue";
import ProfileImage from "@/components/images/ProfileImage.vue";
import { User } from "@/store/users/state";
import { IconType } from "@/types/icons";
import { AllMutationTypes } from "@/store/mutationTypes";
import { useStore } from "vuex";
import Icon from "@/components/icons/Icon/Icon.vue";

interface Props {
  members: User[];
  isAddMembersShown: boolean;
  itemIcon: IconType;
  title: string;
  action?: () => void;
  actionTitle?: string;
  confirmTitle: string;
}

defineProps<Props>();

interface Emits {
  (e: "add-member", member: User): void;
  (e: "item-action", member: User): void;
  (e: "confirm"): void;
}

const emit = defineEmits<Emits>();
const { commit } = useStore();

const addMember = (member: User) => {
  emit("add-member", member);
};
const handleItemAction = (member: User) => {
  emit("item-action", member);
};

const handleConfirm = () => {
  emit("confirm");
  commit(AllMutationTypes.HIDE_POPUP);
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/popup/popup.scss";
@import "src/assets/scss/popup/popupDefault.scss";
</style>
