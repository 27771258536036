import { BridgeActionEnum } from "@/types/webview";
import { postWebviewMessage } from "@/utils/api/postWebviewMessage";
import { useStore } from "vuex";
import { GetterTypes, MutationTypes } from "@/store";
import { AppEnvironment, LayoutTypes } from "@/store/app/state";
import {
  reactive,
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
  nextTick,
  Ref,
} from "vue";
import { useRoute } from "vue-router";
import { getDebouncedFunction } from "@/utils/modifiers";
import { useComputedValue } from "./useComputedValue";

interface Data {
  viewportHeight: number;
  inputElements: NodeListOf<Element> | null;
  focusTimeout: number | null;
}

export const useVisualViewport = (
  rootElement: Ref<HTMLElement | undefined>,
) => {
  const route = useRoute();
  const { getters, commit } = useStore();
  const data = reactive<Data>({
    viewportHeight: window.innerHeight,
    inputElements: null,
    focusTimeout: null,
  });
  const environment = useComputedValue<AppEnvironment>(
    GetterTypes.GET_APP_ENVIRONMENT,
  );
  const isMobileApp = computed(() => environment.value.isMobileApp);

  onMounted(() => {
    if (!isMobileApp.value) {
      return;
    }
    window.visualViewport?.addEventListener("resize", handleViewport);
    postWebviewMessage({ action: BridgeActionEnum.DisableScroll });
    handleFocusEventListener();
  });

  onBeforeUnmount(() => {
    if (!isMobileApp.value) {
      return;
    }
    window.visualViewport?.removeEventListener("resize", handleViewport);
    postWebviewMessage({ action: BridgeActionEnum.EnableScroll });
    handleFocusEventListener();
  });

  watch(route, () => {
    nextTick(() => {
      handleFocusEventListener();
    });
  });

  const messageInputHeight = computed<number>(
    () => getters[GetterTypes.GET_LAYOUT_MESSAGE_INPUT_HEIGHT],
  );
  const layoutType = computed<LayoutTypes>(
    () => getters[GetterTypes.GET_LAYOUT_TYPE],
  );
  const style = computed(() => {
    let appliedHeight: number | string = data.viewportHeight;
    if (appliedHeight) {
      appliedHeight = `${data.viewportHeight}px`;
    }
    let topBarHeight = 58;
    if (layoutType.value === LayoutTypes.DESKTOP) {
      topBarHeight = 76;
    }
    const messageInputStr = messageInputHeight.value
      ? ` ${messageInputHeight.value}px`
      : "";
    const gridTemplateRows = `${topBarHeight}px minmax(100px, 1fr)${messageInputStr}`;
    return { height: appliedHeight, gridTemplateRows };
  });

  const handleFocusEventListener = () => {
    if (!isMobileApp.value) {
      return;
    }
    if (data.inputElements?.length) {
      data.inputElements.forEach((el) => {
        el.removeEventListener("focus", focusHandler);
        el.removeEventListener("touchstart", touchStartHandler);
        el.removeEventListener("touchend", touchEndHandler);
      });
    }
    const inputElements = rootElement?.value?.querySelectorAll(
      "input[type='text'],textarea",
    );
    if (!inputElements?.length) {
      return;
    }
    data.inputElements = inputElements;
    inputElements.forEach((el) => {
      // all these listeners are needed to correctly disable default behaviors on text inputs
      el.addEventListener("focus", focusHandler);
      el.addEventListener("touchstart", touchStartHandler);
      el.addEventListener("touchend", touchEndHandler);
    });
  };
  const focusHandler = (e: Event) => {
    if (data.focusTimeout) {
      window.clearTimeout(data.focusTimeout);
    }
    const target = <HTMLInputElement>e.target;
    target.style.opacity = "0";
    data.focusTimeout = window.setTimeout(() => {
      target.style.opacity = "1";
    });
  };
  const touchStartHandler = (e: Event) => {
    const target = <HTMLInputElement>e.target;
    target.readOnly = true;
  };
  const touchEndHandler = (e: Event) => {
    const target = <HTMLInputElement>e.target;
    target.readOnly = false;
    target.focus({ preventScroll: true });
  };
  const handleUpdatePosition = (viewportHeight: number) => {
    data.viewportHeight = viewportHeight;
    commit(MutationTypes.SET_LAYOUT_HEIGHT, viewportHeight);
    window.scrollTo(0, 0);
  };

  const handleViewport = (e: Event) => {
    runDebouncedViewportHandler(e);
  };
  const runDebouncedViewportHandler = (e: Event) => {
    const debounced = getDebouncedFunction(() => viewportHandler(e), 100);
    debounced();
  };
  const viewportHandler = (e: Event) => {
    const target = <VisualViewport>e.target;
    const viewportHeight = <number>target.height;
    if (!isMobileApp.value) {
      return;
    }
    handleUpdatePosition(viewportHeight);
  };
  return { style };
};
