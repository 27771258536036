<template>
  <div ref="root" class="new-chat-container">
    <MobileNavigationBar
      :title="title"
      :action-title="confirmTitle"
      :handle-redirect="redirect"
      :handle-action="confirmSelection"
      :is-action-disabled="!selectedMembers.length"
    />
    <div class="bottom">
      <div class="groupChat">
        <ChatSidebarSearchBar />
        <div class="groupChatInner">
          <div class="groupMembers">
            <div
              v-for="member in selectedMembers"
              :key="member.itemId"
              class="member"
              @click="removeSelected(member)"
            >
              <ProfileAvatar
                :src="member.pictureSrc"
                :is-online="false"
                :size="ProfileAvatarSizeEnum.Medium"
                :is-delete="true"
              />
            </div>
          </div>
          <h4 v-if="isRecommendationsVisible">
            {{ $t("settings.chat.createGroupChat.usersRecommendations") }}
          </h4>
        </div>
      </div>
      <div class="lists-container" :style="{ height: listsContainerHeight }">
        <NewChatListContainer
          v-for="list in lists"
          :key="list.key"
          :list-items-array="list.items"
          :title="list.title"
          @add-member="addSelected"
          @remove-member="removeSelected"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import MobileNavigationBar from "@/components/layouts/MobileNavigationBar/MobileNavigationBar.vue";
import ProfileAvatar from "@/components/images/ProfileAvatar.vue";
import ChatSidebarSearchBar from "@/components/chat/chatSidebar/ChatSidebarSearchBar.vue";
import NewChatListContainer from "@/components/chat/chatContainer/NewChatListContainer/NewChatListContainer.vue";
import { ProfileAvatarSizeEnum } from "@/types/avatar";
import { IListItem } from "@/types/list";

interface IExtendedListItem {
  key: string;
  title?: string;
  items: IListItem[];
}

interface Props {
  title: string;
  confirmTitle: string;
  lists: IExtendedListItem[];
  listsContainerHeight: string;
  selectedMembers: IListItem[];
  isRecommendationsVisible: boolean;
}

interface Emits {
  (e: "add-selected", member: IListItem): void;
  (e: "remove-selected", member: IListItem): void;
  (e: "confirm"): void;
  (e: "redirect"): void;
}

const emit = defineEmits<Emits>();

defineProps<Props>();

const addSelected = (member: IListItem) => {
  emit("add-selected", member);
};
const removeSelected = (member: IListItem) => {
  emit("remove-selected", member);
};
const confirmSelection = () => {
  emit("confirm");
};
const redirect = () => {
  emit("redirect");
};
</script>
<style lang="scss" scoped>
@import "./SelectChatWrapper.scss";
</style>
