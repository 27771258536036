<template>
  <button
    :id="id"
    class="generic-button"
    :class="{ [variant]: true, 'hidden-content': isLoading }"
    :type="onClick ? 'button' : 'submit'"
    @click="onClick"
  >
    <slot v-if="isVisible" />
    <p v-if="text">{{ appliedText }}</p>
    <Icon v-if="isLoading" :icon="IconType.SPINNER" class="loading-spinner" />
  </button>
</template>

<script setup lang="ts">
import { GenericButtonVariantEnum } from "@/types/button";
import { IconType } from "@/types/icons";
import { Comment, computed, Fragment, defineSlots } from "vue";
import { useI18n } from "vue-i18n";
import Icon from "@/components/icons/Icon/Icon.vue";
import { convertAsArray } from "@/utils/modifiers";

interface Props {
  variant?: GenericButtonVariantEnum;
  text?: string;
  onClick?: () => void;
  disableTranslation?: boolean;
  id?: string;
  isLoading?: boolean;
}

const slots = defineSlots<{ default: () => any }>();
const { t } = useI18n();

const props = withDefaults(defineProps<Props>(), {
  variant: GenericButtonVariantEnum.Primary,
  text: "",
  onClick: undefined,
  disableTranslation: false,
  id: undefined,
  isLoading: false,
});

const isVisible = computed(() => {
  const vnode = slots.default?.();
  const isEmpty =
    !vnode ||
    convertAsArray(vnode).every(
      (vnode) =>
        vnode.type === Comment ||
        (vnode.type === Text && !vnode.children?.length) ||
        (vnode.type === Fragment && !vnode.children?.length),
    );
  return !isEmpty;
});

const appliedText = computed<string>(() => {
  return props.disableTranslation ? props.text : t(props.text);
});
</script>

<style lang="scss" scoped>
@import "src/assets/scss/buttons/genericButton.scss";
</style>
