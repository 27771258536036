<template>
  <div class="top-bar">
    <div class="back" @click="handleClick">
      <Icon :icon="appliedIconName" class="icon" />
    </div>
    <h4 v-if="title" class="title">{{ title }}</h4>
    <p
      v-if="handleAction"
      class="btn"
      :class="{ disabled: isActionDisabled }"
      @click="performAction"
    >
      {{ actionTitle }}
    </p>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";
import { MobileNavigationBarIconType } from "@/types/app";
import Icon from "@/components/icons/Icon/Icon.vue";
import { IconType } from "@/types/icons";
import { MobileNavigationProps } from "@/types/app";

const router = useRouter();

const props = withDefaults(defineProps<MobileNavigationProps>(), {
  hideDivider: false,
  title: "",
  handleRedirect: undefined,
  handleAction: undefined,
  actionTitle: "",
  iconType: MobileNavigationBarIconType.Default,
  isActionDisabled: false,
});

const appliedIconName = computed(() => {
  switch (props.iconType) {
    case MobileNavigationBarIconType.Circle:
      return IconType.BACK_CIRCLE;

    default:
      return IconType.BACK;
  }
});

const handleClick = () => {
  if (props.handleRedirect) {
    props.handleRedirect();
    return;
  }

  router.back();
};

const performAction = () => {
  if (props.isActionDisabled || !props.handleAction) {
    return;
  }
  props.handleAction();
};
</script>
<style lang="scss" scoped>
@import "./MobileNavigationBar";
</style>
