<template>
  <ListItemsSelectNavigationContainer
    :navigation-title="$t('settings.termsAndConditions.title')"
    :lists-array="[menuItems]"
  />
</template>
<script setup lang="ts">
import ListItemsSelectNavigationContainer from "@/components/layouts/ListItemsSelectNavigationContainer/ListItemsSelectNavigationContainer.vue";
import { getSettingsTermsMenuItems } from "@/utils/settings";
import { useComputedValue } from "@/composables";
import { GetterTypes } from "@/store";
import { LocaleTypeEnum } from "@/types/localization";

const locale = useComputedValue<LocaleTypeEnum>(GetterTypes.GET_LANGUAGE);
const menuItems = getSettingsTermsMenuItems(locale.value);
</script>
<style lang="scss" scoped>
@import "src/assets/scss/settings/settingsAll";
</style>
