import {
  useComputedValue,
  useGetChangeChatMobileContainerProperties,
} from "@/composables";
import { ActionTypes, GetterTypes } from "@/store";
import { ChatState } from "@/store/chats/types";
import { createSingleChatId } from "@/utils/chat";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export const useGetNewChatMobileContainerProperties = () => {
  const {
    listsContainerHeight,
    handleRedirect,
    selectedGroupMembers,
    handleAddGroupMember,
    handleRemoveGroupMember,
    isRecommendationsVisible,
    lists,
    otherPeople,
  } = useGetChangeChatMobileContainerProperties({ isNewChat: true });

  const router = useRouter();
  const { getters, dispatch } = useStore();

  const authId = useComputedValue<number>(GetterTypes.GET_AUTH_ID);

  const handleCreateChat = async () => {
    const chatGroupLength = selectedGroupMembers.value.length;
    if (chatGroupLength === 0) {
      return;
    }

    // it is a group chat
    if (chatGroupLength > 1) {
      const memberIds = selectedGroupMembers.value.map((m) =>
        m.itemId!.toString(),
      );
      const members = selectedGroupMembers.value.map((member) => {
        otherPeople.value.find((p) => p.id === member.itemId);
      });
      const chatId: string = await dispatch(
        ActionTypes.CREATE_CHAT_WITH_MESSAGE,
        {
          messageText: "",
          memberIds,
          members,
        },
      );

      router.push(`/${chatId}`);
      return;
    }
    // it is a oneToOne chat
    const member = selectedGroupMembers.value[0];
    const partner = otherPeople.value.find((p) => p.id === member.itemId);
    if (!partner) {
      return;
    }
    const partnerId = partner.id.toString();
    const chatId = createSingleChatId(authId.value.toString(), partnerId!);
    const chat: ChatState = getters.GET_CHAT(chatId);
    if (chat) {
      router.push(`/${chat.chatId}`);
      return;
    }

    const newChatId: string = await dispatch(
      ActionTypes.CREATE_CHAT_WITH_MESSAGE,
      {
        messageText: "",
        memberIds: [partnerId],
        members: [partner],
      },
    );
    router.push(`/${newChatId}`);
  };

  return {
    lists,
    listsContainerHeight,
    handleRedirect,
    selectedGroupMembers,
    handleAddGroupMember,
    handleRemoveGroupMember,
    handleCreateChat,
    isRecommendationsVisible,
  };
};
