import { useComputedValue, useVisualViewport } from "@/composables";
import { searchUsersByNameCallbackAsync } from "@/services/maitrejaApi/search";
import { GetterTypes, MutationTypes } from "@/store";
import { AppEnvironment } from "@/store/app/state";
import { Profile } from "@/store/auth/state";
import { Chat, ChatTypes } from "@/store/chats/types";
import { FriendUser, PhoneContact } from "@/store/users/state";
import { ChatTypeProvideObject } from "@/types/chat";
import { IconType } from "@/types/icons";
import { IListItem, ListItemTypeEnum } from "@/types/list";
import { IUserPhoneContactUser } from "@/types/users";
import { chatTypeInjectionKey } from "@/utils/chat/chatTypeInjectionKey";
import { getFullPhoneNumberString } from "@/utils/form";
import { ref, computed, watch, inject, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

interface UseGetChangeChatMobileContainerPropertiesArgs {
  isNewChat: boolean;
  enableSameChat?: boolean;
}

export const useGetChangeChatMobileContainerProperties = ({
  isNewChat = false,
  enableSameChat = false,
}: UseGetChangeChatMobileContainerPropertiesArgs) => {
  const root = ref<HTMLElement>();
  useVisualViewport(root);
  const route = useRoute();
  const router = useRouter();
  const chatId = route.params.chat_id;
  const selectedGroupMembers = ref<IListItem[]>([]);
  const otherPeople = ref<Profile[]>([]);

  const injected = inject<ChatTypeProvideObject>(chatTypeInjectionKey, {
    type: ChatTypes.ONE_TO_ONE_CHAT,
  });

  const { t } = useI18n();
  const { getters, commit } = useStore();
  const friends = useComputedValue<FriendUser[]>(
    GetterTypes.GET_AUTH_FRIEND_ARRAY,
  );
  const phoneContacts = useComputedValue<PhoneContact[]>(
    GetterTypes.GET_PHONE_CONTACTS,
  );
  const phoneContactsUsers = useComputedValue<IUserPhoneContactUser[]>(
    GetterTypes.GET_PHONE_CONTACTS_USERS,
  );
  const accessToken = useComputedValue<string>(
    GetterTypes.GET_AUTH_ACCESS_TOKEN,
  );
  const authId = useComputedValue<number>(GetterTypes.GET_AUTH_ID);
  const filter = useComputedValue<string>(GetterTypes.GET_CHATS_FILTER);
  const layoutHeight = useComputedValue<string>(GetterTypes.GET_LAYOUT_HEIGHT);
  const initialLoadCompleted = useComputedValue<boolean>(
    GetterTypes.GET_CHATS_INITIAL_LOAD,
  );
  const environment = useComputedValue<AppEnvironment>(
    GetterTypes.GET_APP_ENVIRONMENT,
  );
  const isMobileApp = computed(() => environment.value.isMobileApp);

  onMounted(() => {
    if (injected.type === ChatTypes.ONE_TO_ONE_CHAT) {
      return;
    }

    addCurrentPartner();
  });

  watch(initialLoadCompleted, () => {
    if (enableSameChat) {
      return;
    }
    addCurrentPartner();
  });

  const addCurrentPartner = () => {
    if (!isNewChat || !initialLoadCompleted) {
      return;
    }
    const chat: Chat | undefined = getters.GET_CONVERTED_CHAT_DETAIL(chatId);
    if (!chat?.partner) {
      return;
    }
    const partner = chat.partner;
    const { name, picture, id } = partner;
    const partnerListItem = {
      text: name,
      pictureSrc: picture,
      type: ListItemTypeEnum.Chat,
      itemId: id,
      isOnline: false,
    };

    selectedGroupMembers.value.push(partnerListItem);
  };

  const handleAddGroupMember = (item: IListItem) => {
    if (
      selectedGroupMembers.value.find((member) => member.itemId === item.itemId)
    ) {
      return;
    }
    selectedGroupMembers.value.push(item);
  };

  const handleRemoveGroupMember = (item: IListItem) => {
    selectedGroupMembers.value = selectedGroupMembers.value.filter(
      (m) => m.itemId !== item.itemId,
    );
  };

  const friendsListItemsArray = computed<IListItem[]>(() => {
    const arr = friends.value.map(({ name, picture, id, alias }) => {
      return {
        text: name,
        pictureSrc: picture,
        type: ListItemTypeEnum.Chat,
        itemId: id,
        isOnline: false,
        subtext: alias,
      };
    });

    return getFilteredArray(arr);
  });

  const phoneContactsRecommendListItemsArray = computed<IListItem[]>(() => {
    const arr: IListItem[] = phoneContacts.value.map(({ name, phone }, i) => {
      return {
        text: name,
        iconName: IconType.DEFAULT_SINGLE_GRAY,
        type: ListItemTypeEnum.Chat,
        itemId: i,
        isOnline: false,
        phoneNumber: phone,
        isNotInvited: true,
      };
    });

    return getFilteredArray(arr);
  });

  const phoneContactsUsingAppListItemsArray = computed<IListItem[]>(() => {
    const arr = phoneContactsUsers.value.map(
      ({ phoneNumber, countryCode, name, id, alias }) => {
        return {
          text: name,
          iconName: IconType.DEFAULT_SINGLE_GRAY,
          type: ListItemTypeEnum.Chat,
          itemId: id,
          isOnline: false,
          phoneNumber: getFullPhoneNumberString(countryCode, phoneNumber),
          subtext: alias,
        };
      },
    );
    return getFilteredArray(arr);
  });

  const otherPeopleListItemsArray = computed<IListItem[]>(() => {
    if (!filter.value) {
      return [];
    }

    const items = otherPeople.value
      .filter((user) => user.id !== authId.value)
      .map(({ name, picture, id }) => {
        return {
          text: name,
          pictureSrc: picture,
          type: ListItemTypeEnum.Chat,
          itemId: id,
          isOnline: false,
        };
      });

    const filtered = items
      .map((user) => {
        const isPhoneContact = phoneContactsUsingAppListItemsArray.value.some(
          (phoneContact) => phoneContact.itemId === user.itemId,
        );
        const isFriend = friendsListItemsArray.value.some(
          (phoneContact) => phoneContact.itemId === user.itemId,
        );
        if (!isPhoneContact && !isFriend) {
          return user;
        }
      })
      .filter(Boolean) as IListItem[];
    return getFilteredArray(filtered);
  });

  const getFilteredArray = (arr: IListItem[]): IListItem[] => {
    const filtered: IListItem[] = [];
    arr.forEach((it) => {
      const isSelected = selectedGroupMembers.value.find(
        (member) => member.itemId === it.itemId,
      );

      const isOneToOne = injected.type === ChatTypes.ONE_TO_ONE_CHAT;
      const isGroupNotSelected =
        injected.type === ChatTypes.GROUP_CHAT && !isSelected;
      const isGroupNotNew =
        injected.type === ChatTypes.GROUP_CHAT && !isNewChat;
      const isKept = isOneToOne || isGroupNotSelected || isGroupNotNew;

      if (!isKept) return;

      if (isNewChat) {
        filtered.push(it);
        return filtered;
      }

      const chatDetail: Chat = getters.GET_CONVERTED_CHAT_DETAIL(chatId);

      const alreadyGroupMembers =
        chatDetail.members?.map((member) => member.id) ?? [];

      let updatedItemType = ListItemTypeEnum.Checkmark;
      if (isSelected) {
        updatedItemType = ListItemTypeEnum.Checkmarked;
      }
      if (
        !enableSameChat &&
        it.itemId &&
        alreadyGroupMembers.includes(it.itemId)
      ) {
        updatedItemType = ListItemTypeEnum.CheckmarkedDisabled;
      }

      const updatedItem = {
        ...it,
        type: updatedItemType,
        isPictureDisplayed: true,
      };

      filtered.push(updatedItem);
    });

    return filtered;
  };

  const listsContainerHeight = computed<string>(() => {
    return `calc(${layoutHeight.value}px - 130px)`;
  });

  watch(filter, () => {
    searchUsersByNameCallbackAsync({
      searchUsername: filter.value,
      accessToken: accessToken.value,
      setUsers: setOtherPeople,
      isMobileApp: isMobileApp.value,
    });
  });

  const setOtherPeople = (users: Profile[]): void => {
    otherPeople.value = users;
  };

  const handleRedirect = async () => {
    const type = injected.type;
    if (type === ChatTypes.NEW_CHAT_TYPE) {
      return;
    }
    commit(MutationTypes.CLEAR_CHATS_FILTER);
    if (type === ChatTypes.ONE_TO_ONE_CHAT) {
      router.push("/");
      return;
    }

    router.back();
  };

  const isRecommendationsVisible = computed(() => {
    const isVisible =
      friendsListItemsArray.value.length ||
      phoneContactsUsingAppListItemsArray.value.length;

    return !!isVisible;
  });

  const restoreDefaults = () => {
    selectedGroupMembers.value = [];
    otherPeople.value = [];
  };

  const lists = computed(() => [
    { key: "friends", items: friendsListItemsArray.value },
    {
      key: "yourContacts",
      title: t("chat.chatContainer.NewChatListContainer.yourContacts"),
      items: phoneContactsUsingAppListItemsArray.value,
    },
    {
      key: "otherPeople",
      title: t("chat.chatContainer.NewChatListContainer.otherPeople"),
      items: otherPeopleListItemsArray.value,
    },
    {
      key: "recommend",
      title: t("chat.chatContainer.NewChatListContainer.recommend"),
      items: phoneContactsRecommendListItemsArray.value,
    },
  ]);

  return {
    listsContainerHeight,
    friendsListItemsArray,
    otherPeopleListItemsArray,
    phoneContactsRecommendListItemsArray,
    phoneContactsUsingAppListItemsArray,
    handleRedirect,
    injected,
    selectedGroupMembers,
    otherPeople,
    handleAddGroupMember,
    handleRemoveGroupMember,
    isRecommendationsVisible,
    lists,
    restoreDefaults,
  };
};
