/**
 * Converts the input argument into an array.
 * If the argument is already an array, it is returned as-is.
 * If the argument is a non-null single value, it is wrapped in an array.
 * If the argument is null, an empty array is returned.
 *
 * @param arg - The input value which can be a single item, an array, or null.
 * @returns An array representation of the input argument.
 */

export const convertAsArray = <T>(arg: T | T[] | null) => {
  return Array.isArray(arg) ? arg : arg !== null ? [arg] : [];
};
