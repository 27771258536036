<template>
  <SettingsDetails
    :title="$t('settings.activateAccountContent.activationOfAccount')"
  >
    <div class="details-content">
      <Form @submit-form="handleSubmit">
        <div class="container">
          <div class="content">
            <Input
              v-model="data.name"
              :name="FormFieldNameEnum.Name"
              label="settings.personalDetailsContent.fullName"
              placeholder="auth.name"
              required
              :errors="data.errors"
            />
            <Input
              v-model="data.email"
              :name="FormFieldNameEnum.Email"
              label="auth.email"
              placeholder="auth.email"
              required
              :errors="data.errors"
            />
            <Input
              v-model="data.password"
              :name="FormFieldNameEnum.Password"
              :type="FormFieldType.Password"
              label="auth.password"
              placeholder="auth.password"
              required
              :errors="data.errors"
            />
          </div>
          <GenericButton
            :variant="GenericButtonVariantEnum.Primary"
            text="settings.activateAccount"
          />
        </div>
      </Form>
    </div>
  </SettingsDetails>
</template>
<script setup lang="ts">
import SettingsDetails from "@/components/settings/SettingsDetails/SettingsDetails.vue";
import Form from "@/components/layouts/Form.vue";
import GenericButton from "@/components/buttons/GenericButton.vue";
import { ActionTypes } from "@/store";
import { GenericButtonVariantEnum } from "@/types/button";
import {
  FormFieldNameEnum,
  FormFieldType,
  FormType,
  RegisterFormDataObjectType,
} from "@/types/form";
import {
  getInitialFormData,
  getIsFormValid,
  registerFormFields,
} from "@/utils/form";
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Input from "../forms/Input.vue";

const data = reactive(
  getInitialFormData<RegisterFormDataObjectType>(registerFormFields),
);
const { dispatch } = useStore();
const router = useRouter();

// adjust submit handler
const handleSubmit = async () => {
  const { errors, ...fields } = data;

  const isFormValid = getIsFormValid(
    data,
    registerFormFields,
    FormType.Register,
  );
  if (!isFormValid) {
    return;
  }

  try {
    await dispatch(ActionTypes.UPDATE_USER, {
      name: data.name,
      email: data.email,
      password: data.password,
    });
    data.errors = fields;
    router.replace("/settings");
  } catch (error) {
    dispatch(ActionTypes.MOUNT_SNACKBAR, {
      title: "snackbar.errors.wrongCredentials.title",
      text: "snackbar.errors.wrongCredentials.text",
    });
  }
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/settings/settingsPersonalDetails";
</style>
