const isFulfilled = <T>(
  p: PromiseSettledResult<T>,
): p is PromiseFulfilledResult<T> => p.status === "fulfilled";

export const getPromisesSuccessValues = async <T>(promises: Promise<T>[]) => {
  const results = await Promise.allSettled(promises);

  return results
    .filter(isFulfilled)
    .map((p) => p.value)
    .filter(Boolean) as Exclude<T, null | undefined>[];
};
