<template>
  <DesktopSelectChatWrapper
    :members="members"
    :action="showAddMembers"
    :item-icon="IconType.TRASH"
    :title="$t('popup.PopupEditGroupMembers.headerMain')"
    :action-title="$t('popup.PopupEditGroupMembers.headerAdd')"
    :confirm-title="$t('popup.PopupEditGroupMembers.btnSave')"
    :is-add-members-shown="isAddMembersShown"
    @add-member="addMember"
    @item-action="removeMember"
    @confirm="saveChanges"
  />
</template>

<script setup lang="ts">
import { GetterTypes } from "@/store";
import { AllActionTypes } from "@/store/actionTypes";
import { IconType } from "@/types/icons";
import DesktopSelectChatWrapper from "../chat/chatContainer/DesktopSelectChatWrapper/DesktopSelectChatWrapper.vue";
import {
  useComputedValue,
  useGetDesktopChatSelectProperties,
} from "@/composables";
import { Chat } from "@/store/chats/types";
import { computed } from "vue";
import { User } from "@/store/users/state";
import { getDifferenceArray } from "@/utils/modifiers";
import { useStore } from "vuex";

const chat = useComputedValue<Chat>(GetterTypes.GET_SELECTED_CHAT);
const chatMembers = computed(() => chat.value.members ?? []);
const { dispatch } = useStore();

const {
  isAddMembersShown,
  members,
  addMember: defaultAddMember,
  removeMember,
  showAddMembers,
} = useGetDesktopChatSelectProperties({
  defaultMembers: chatMembers.value.filter(
    (member) => member.id !== chat.value.createdBy,
  ),
});

const addMember = (member: User) => {
  defaultAddMember(member);
  isAddMembersShown.value = false;
};

const saveChanges = () => {
  const memberIds = members.value.map((m) => String(m.id));
  const chatMemberIds = chatMembers.value.map((m) => String(m.id));
  const addMemberIds = getDifferenceArray(memberIds, chatMemberIds);
  const removeMemberIds = getDifferenceArray(chatMemberIds, memberIds);

  dispatch(AllActionTypes.UPDATE_GROUP_CHAT_MEMBERS, {
    chatId: chat.value.id,
    addMemberIds,
    removeMemberIds,
  });
};
</script>
