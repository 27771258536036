<template>
  <div
    class="message-extras"
    :class="{ isSelecting: !!selectedMessageIds, isMine: !!isMine }"
  >
    <div class="message-extras-top">
      <Icon v-if="icon" :icon="icon" />
      <span class="message-extras-title"> {{ title }}</span>
    </div>
    <slot />
  </div>
</template>

<script setup lang="ts">
import { IconType } from "@/types/icons";
import Icon from "@/components/icons/Icon/Icon.vue";
import { useComputedValue } from "@/composables";
import { GetterTypes } from "@/store";

interface MessageExtrasWrapperProps {
  title: string;
  icon?: IconType;
  isMine?: boolean;
}

defineProps<MessageExtrasWrapperProps>();

const selectedMessageIds = useComputedValue<string[] | null>(
  GetterTypes.GET_SELECTED_MESSAGE_IDS,
);
</script>

<style lang="scss">
@import "./MessageExtrasWrapper.scss";
</style>
