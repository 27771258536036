import { useGetChangeChatMobileContainerProperties } from "../../../../composables/useGetChangeChatMembersProperties";
import { useComputedValue, useGetMessageHandlers } from "@/composables";
import { createOneToOneChatIdsFromUserIds } from "@/utils/chat";
import { Profile } from "@/store/auth/state";
import { GetterTypes } from "@/store";
import { computed } from "vue";

export const useGetForwardMessageSelectProperties = () => {
  const {
    listsContainerHeight,
    selectedGroupMembers,
    handleAddGroupMember,
    handleRemoveGroupMember,
    isRecommendationsVisible,
    lists,
    restoreDefaults,
  } = useGetChangeChatMobileContainerProperties({
    isNewChat: false,
    enableSameChat: true,
  });
  const profile = useComputedValue<Profile>(GetterTypes.GET_AUTH_PROFILE);
  const { handleSendForwardedMessage, clearForwardedMessage } =
    useGetMessageHandlers();

  const sendForwardedMessage = async () => {
    const userIds = selectedGroupMembers.value
      .map((m) => m.itemId)
      .filter(Boolean) as number[];
    const chatIds = createOneToOneChatIdsFromUserIds(userIds, profile.value.id);

    handleSendForwardedMessage(chatIds);
    clearAll();
  };

  const reducedLists = computed(() => lists.value.slice(0, -1));

  const clearAll = () => {
    clearForwardedMessage();
    restoreDefaults();
  };

  return {
    listsContainerHeight,
    handleRedirect: clearAll,
    handleAddGroupMember,
    handleRemoveGroupMember,
    sendForwardedMessage,
    isRecommendationsVisible,
    selectedGroupMembers,
    lists: reducedLists,
  };
};
