<template>
  <SelectChatWrapper
    :lists-container-height="listsContainerHeight"
    :lists="lists"
    :is-recommendations-visible="isRecommendationsVisible"
    :selected-members="selectedGroupMembers"
    :title="$t('settings.chat.forwardMessage.title')"
    :confirm-title="$t('settings.chat.forwardMessage.actionTitle')"
    @add-selected="handleAddGroupMember"
    @remove-selected="handleRemoveGroupMember"
    @redirect="handleRedirect"
    @confirm="sendForwardedMessage"
  />
</template>
<script setup lang="ts">
import { useGetForwardMessageSelectProperties } from "./useGetForwardMessageSelectProperties";
import SelectChatWrapper from "../SelectChatWrapper/SelectChatWrapper.vue";

const {
  listsContainerHeight,
  lists,
  handleRedirect,
  selectedGroupMembers,
  handleAddGroupMember,
  handleRemoveGroupMember,
  sendForwardedMessage,
  isRecommendationsVisible,
} = useGetForwardMessageSelectProperties();
</script>
