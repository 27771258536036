import { config } from "@/config";
import { chatApiAxios } from "@/plugins";
import { checkAccessTokenAsync } from "@/services";
import { HttpRequestMethodEnum } from "@/types/api";
import { IUpdateAttachmentSourcesArgs } from "@/types/chat";
import { getCommonJsonHeaders } from "@/utils/api";

export const updateAttachmentSourcesBeAsync = async (
  data: IUpdateAttachmentSourcesArgs,
) => {
  const accessToken = await checkAccessTokenAsync();

  const res = await chatApiAxios(
    config.api.chatApi.endpoints.message.updateAttachmentSources,
    {
      method: HttpRequestMethodEnum.POST,
      headers: getCommonJsonHeaders(accessToken),
      data,
    },
  );

  return res.data;
};
