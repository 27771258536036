import { AllActionTypes } from "@/store/actionTypes";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useGetChangeChatMobileContainerProperties } from "../../../../composables/useGetChangeChatMembersProperties";
import { computed } from "vue";

export const useGetEditChatMobileContainerProperties = () => {
  const { dispatch } = useStore();
  const router = useRouter();
  const route = useRoute();
  const chatId = route.params.chat_id;
  const {
    listsContainerHeight,
    handleRedirect,
    selectedGroupMembers,
    handleAddGroupMember,
    handleRemoveGroupMember,
    isRecommendationsVisible,
    lists,
  } = useGetChangeChatMobileContainerProperties({ isNewChat: false });

  const handleSaveAddedMembers = async () => {
    const addMemberIds = selectedGroupMembers.value.map((m) => m.itemId);

    dispatch(AllActionTypes.UPDATE_GROUP_CHAT_MEMBERS, {
      chatId,
      addMemberIds,
    });

    router.push(`/${chatId}`);
  };

  const reducedLists = computed(() => lists.value.slice(0, -1));

  return {
    listsContainerHeight,
    handleRedirect,
    handleAddGroupMember,
    handleRemoveGroupMember,
    handleSaveAddedMembers,
    isRecommendationsVisible,
    selectedGroupMembers,
    lists: reducedLists,
  };
};
