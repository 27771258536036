import { createChatId } from "./createCachedRawChat";

export const createOneToOneChatIdsFromUserIds = (
  userIds: number[],
  authId: number,
) =>
  userIds.map((userId) => {
    const authIdStr = String(authId);
    return createChatId(authIdStr, [authIdStr, String(userId)]);
  });
