<template>
  <slot />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { Message, NonAutomaticMessage, TextMessage } from "@/store/chats/types";
import { messageInjectionKey } from "@/utils/chat";
import { provide } from "vue";
import {
  getIsAutomaticMessageType,
  getIsMessageTextType,
} from "@/utils/message";
import { useStore } from "vuex";
import { ActionTypes, GetterTypes, MutationTypes } from "@/store";

import { useComputedValue } from "@/composables";
import { AppEnvironment, LayoutTypes, PopupTypes } from "@/store/app/state";

const { commit, dispatch } = useStore();
const replyMessage = ref<NonAutomaticMessage | null>(null);
const forwardedMessage = ref<NonAutomaticMessage | null>(null);
const environment = useComputedValue<AppEnvironment>(
  GetterTypes.GET_APP_ENVIRONMENT,
);
const layoutType = useComputedValue<LayoutTypes>(GetterTypes.GET_LAYOUT_TYPE);
const isMobile = computed(
  () =>
    layoutType.value === LayoutTypes.MOBILE && environment.value.isMobileApp,
);
const handleReplyToMessage = (message: Message) => {
  if (getIsAutomaticMessageType(message)) {
    return;
  }
  replyMessage.value = message;
  modifiedMessage.value = null;
};
const modifiedMessage = ref<TextMessage | null>(null);
const handleModifyMessage = (message: Message) => {
  if (!getIsMessageTextType(message)) {
    return;
  }
  modifiedMessage.value = message;
  replyMessage.value = null;
};

const handleRemoveMessage = (message: Message) => {
  dispatch(ActionTypes.DELETE_MESSAGES, { messageIds: [message.id] });
};

const clearInjectedMessage = () => {
  replyMessage.value = null;
  modifiedMessage.value = null;
};

const openForwardMessageDialog = (message: Message) => {
  if (getIsAutomaticMessageType(message)) {
    return;
  }
  const { reactions, replyToMessage, ...restMessage } = message;
  forwardedMessage.value = restMessage;

  if (isMobile.value) {
    return;
  }
  // if on desktop, open popup
  commit(MutationTypes.SHOW_POPUP, {
    popupType: PopupTypes.FORWARD_MESSAGE,
  });
};

const handleSendForwardedMessage = (chatIds: string[]) => {
  const forwardedMess = forwardedMessage.value;
  if (!forwardedMess) {
    return;
  }

  const resultMessage: NonAutomaticMessage = getIsMessageTextType(forwardedMess)
    ? { ...forwardedMess, isModified: false }
    : forwardedMess;

  dispatch(ActionTypes.FORWARD_MESSAGE, {
    chatIds,
    message: resultMessage,
  });
};

const clearForwardedMessage = () => {
  forwardedMessage.value = null;
};

provide(messageInjectionKey, {
  replyMessage,
  handleReplyToMessage,
  clearInjectedMessage,
  modifiedMessage,
  handleModifyMessage,
  handleRemoveMessage,
  openForwardMessageDialog,
  forwardedMessage,
  handleSendForwardedMessage,
  clearForwardedMessage,
});
</script>
