<template>
  <SettingsDetails :title="$t('settings.personalDetails')">
    <div class="details-content">
      <Form @submit-form="handleSubmit">
        <div class="container">
          <div class="content">
            <Input
              v-model="name"
              :name="FormFieldNameEnum.Name"
              label="settings.personalDetailsContent.fullName"
              required
            />
          </div>
          <GenericButton
            :variant="GenericButtonVariantEnum.Primary"
            text="settings.personalDetailsContent.saveDetails"
          />
        </div>
      </Form>
    </div>
  </SettingsDetails>
</template>
<script setup lang="ts">
import SettingsDetails from "@/components/settings/SettingsDetails/SettingsDetails.vue";
import Form from "@/components/layouts/Form.vue";
import GenericButton from "@/components/buttons/GenericButton.vue";
import { useComputedValue } from "@/composables";
import { ActionTypes, GetterTypes } from "@/store";
import { Profile } from "@/store/auth/state";
import { GenericButtonVariantEnum } from "@/types/button";
import { FormFieldNameEnum } from "@/types/form";
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Input from "../forms/Input.vue";

const name = ref("");
const router = useRouter();
const { dispatch } = useStore();

const profile = useComputedValue<Profile>(GetterTypes.GET_AUTH_PROFILE);

watch(profile, () => {
  hydrateData();
});

const handleSubmit = async () => {
  await dispatch(ActionTypes.UPDATE_USER, {
    name: name.value,
    photo: profile.value.picture,
  });

  router.push("/settings");
};
const hydrateData = () => {
  name.value = profile.value.name;
};

hydrateData();
</script>
<style lang="scss" scoped>
@import "src/assets/scss/settings/settingsPersonalDetails";
</style>
