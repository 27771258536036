import { ChatsActionTypes } from "./actionTypes";
import { ChatsActionType } from "./actionState";
import { ChatsMutationTypes } from "./mutationTypes";
import firebase from "@/firebase/firebase";
import {
  createMessageId,
  getPartnerIdFromChatId,
  isOneToOneChat,
} from "@/firebase/utils";
import { ActionTypes, MutationTypes } from "..";
import {
  GroupRawChat,
  GroupRawTextMessage,
  OneToOneRawTextMessage,
  FirestoreDocumentChangeType,
  RawChat,
  RawMessage,
  UpdateChatMembersArgs,
  ChatState,
  MessageType,
} from "./types";
import { UsersMutationTypes } from "../users";
import { getChatRef, getMessagesRef, getUserRef } from "@/firebase/ref";
import {
  createChatBeAsync,
  deleteChatBeAsync,
  leaveChatBeAsync,
  updateChatBeAsync,
  updateChatMembersBeAsync,
} from "@/services/chatApi/chat";
import {
  deleteAttachmentBeAsync,
  deleteMessagesBeAsync,
  getVideoDetailsBeAsync,
  markMessageAsReadBeAsync,
  markOneToOneMessageAsRead,
  removeMessageReaction,
  forwardMessageBeAsync,
  sendMessageBeAsync,
  sendMessageReaction,
  updateAttachmentProgressBeAsync,
  updateAttachmentSourcesBeAsync,
  uploadAllFilesAsync,
} from "@/services/chatApi/message";
import { User } from "../users/state";
import { getUserByIdAsync } from "@/services/maitrejaApi/users";
import {
  addLatestChatListener,
  analyzeNewUserChatIds,
  createCachedRawChat,
  getUpdatedReactionItems,
  optimisticUpdateReactions,
} from "@/utils/chat";
import {
  getAttachmentsForDeletionArgs,
  getIsMessageFriendRequestType,
  getIsOneToOneRawAudioMessage,
  getIsOneToOneRawTextMessage,
  getIsRawMessageAudioType,
  getIsRawMessageAutomaticType,
  getIsRawMessageCallType,
  getIsRawMessageFriendRequestType,
  getIsRawMessageTextType,
} from "@/utils/message";
import _ from "lodash";
import { checkUserBlockingAsync } from "@/services/maitrejaApi";
import { config } from "@/config";
import { FirestoreUserProfile } from "../auth/types";
import { AuthMutationTypes } from "../auth";
import {
  IForwardMessageItem,
  IUpdateAttachmentSourcesArgs,
  VideoDisplayStatus,
} from "@/types/chat";
import { getChatUnsubscribeById } from "@/utils/chat/getChatUnsubscribeById";
import { getIsOneToOneRawChat } from "@/utils/message/getIsOneToOneRawChat";
import { getPromisesSuccessValues } from "@/utils/api";
import { getDifferenceArray } from "@/utils/modifiers";

export const chatsActions: ChatsActionType = {
  [ChatsActionTypes.ADD_AUTH_CHATS_LISTENER]({ commit, dispatch, rootState }) {
    const authId = rootState.auth.profile?.id;

    if (!authId) {
      throw new Error("authId is not defined");
    }

    const snapshotCallback = async (
      snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>,
    ) => {
      const data = snapshot.data() as FirestoreUserProfile;
      const firebaseChatIds: string[] = data?.chats || [];
      const { chatIds, allChatIds } = rootState.chats;
      addLatestChatListener(chatIds, firebaseChatIds);

      const { removedIds, newIds } = analyzeNewUserChatIds(
        firebaseChatIds,
        allChatIds,
      );
      removedIds.forEach((chatId) => {
        commit(ChatsMutationTypes.REMOVE_CHAT, { chatId });
      });
      const addChatListenersPromises = newIds.map(async (chatId) => {
        dispatch(ActionTypes.ADD_CHAT_LISTENER, { chatId });
      });

      await Promise.all(addChatListenersPromises);
      // @ts-expect-error
      commit(AuthMutationTypes.SET_RINGING_CALLS, data.ringingCalls);
    };
    const authRef = getUserRef(authId);
    const unsubscribe = authRef.onSnapshot(snapshotCallback);

    commit(ChatsMutationTypes.SET_AUTH_CHATS_UNSUBSCRIBE, {
      unsubscribe,
    });
  },
  [ChatsActionTypes.ADD_CHAT_LISTENER](
    { commit, dispatch, rootState },
    { chatId, delay = 1 },
  ) {
    const authId = rootState.auth.profile?.id;
    const accessToken = rootState.auth.accessToken;
    const authProfile = rootState.auth.profile;
    if (!chatId || !authId || !accessToken || !authProfile) {
      return;
    }

    const chatRef = getChatRef(chatId);
    const snapshotCallback = async (
      snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>,
    ) => {
      const rawChat = snapshot.data() as RawChat;
      if (!rawChat) {
        return;
      }
      dispatch(ChatsActionTypes.ADD_CHAT_DATA, { chatId, rawChat });
    };

    const afterSnapshotCallback = _.after(delay, snapshotCallback);
    const unsubscribe = chatRef.onSnapshot(afterSnapshotCallback);

    commit(ChatsMutationTypes.SET_CHAT_UNSUBSCRIBE, {
      chatId,
      unsubscribe,
    });
  },
  async [ChatsActionTypes.ADD_CHAT_DATA](
    { commit, rootState, rootGetters },
    { chatId, rawChat },
  ) {
    const authId = rootState.auth.profile?.id;
    const accessToken = rootState.auth.accessToken;

    if (!chatId || !authId || !accessToken) {
      return;
    }
    if (getIsOneToOneRawChat(rawChat)) {
      const partnerId = getPartnerIdFromChatId(authId, chatId);
      const partner: User | undefined = rootGetters.GET_USER(partnerId);
      if (!partner) {
        try {
          const promisesArray = [
            getUserByIdAsync(accessToken, partnerId),
            checkUserBlockingAsync(accessToken, partnerId),
          ];
          const [newPartner, blockingStatus] = await Promise.all(promisesArray);

          if (!newPartner?.id) {
            return;
          }

          const newUser: User = { ...newPartner, blockingStatus };

          // @ts-expect-error
          commit(UsersMutationTypes.ADD_USER, newUser);
        } catch (error) {
          console.log({ error });
        }
      }

      commit(ChatsMutationTypes.SET_CHAT_DATA, { chatId, rawChat });

      return;
    }

    const groupRawChat = rawChat as GroupRawChat;
    const memberIds = groupRawChat.members;
    const membersPromise = memberIds.map(async (memberId) => {
      let member: User = rootGetters.GET_USER(memberId);
      if (!member) {
        // @ts-expect-error
        member = await getUserByIdAsync(accessToken, memberId);
      }

      return member;
    });

    const members: User[] = (await Promise.all(membersPromise)).filter(
      (member) => member?.id,
    );
    members.forEach((member) => {
      // @ts-expect-error
      commit(UsersMutationTypes.ADD_USER, member);
    });
    commit(ChatsMutationTypes.SET_CHAT_DATA, { chatId, rawChat });
  },
  async [ChatsActionTypes.ADD_MESSAGES_LISTENER](
    { commit, rootState, getters },
    { chatId },
  ) {
    const chat: ChatState = getters.GET_CHAT(chatId);
    if (chat?.messagesUnsubscribe) {
      return;
    }

    try {
      const authId = rootState.auth.profile?.id;
      const accessToken = rootState.auth.accessToken;
      const authProfile = rootState.auth.profile;
      if (!chatId || !authId || !accessToken || !authProfile) return;

      const messagesRef = getMessagesRef(chatId);
      const snapshotCallback = async (
        snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
      ) => {
        const changes = snapshot.docChanges();
        changes.forEach((change) => {
          const rawMessage = change.doc.data() as RawMessage;
          const changeType = change.type as FirestoreDocumentChangeType;
          commit(ChatsMutationTypes.SET_MESSAGE_DATA, {
            changeType,
            rawMessage: { ...rawMessage, chatId },
            chatId,
          });
        });
      };
      const afterSnapshotCallback = _.after(
        config.constants.AFTER_FUNCTION_COUNT,
        snapshotCallback,
      );
      const unsubscribe = messagesRef.onSnapshot(afterSnapshotCallback);

      commit(ChatsMutationTypes.SET_MESSAGES_UNSUBSCRIBE, {
        chatId,
        unsubscribe,
      });

      // @ts-expect-error
    } catch (error: Error) {
      console.log(error);
    }
  },

  async [ChatsActionTypes.CREATE_CHAT_WITH_MESSAGE](
    { commit, rootState },
    { memberIds, messageText, attachments, members, messageType },
  ): Promise<string | undefined> {
    try {
      const firebaseIdToken = rootState.auth.firebaseIdToken;
      const userId = rootState.auth.profile.id;
      if (!memberIds || memberIds?.length === 0 || !firebaseIdToken) {
        return;
      }

      const rawChat = createCachedRawChat({
        creatorId: String(userId),
        memberIds,
        messageText,
        attachments,
        messageType,
      });

      const { id: chatId } = rawChat;
      // performing optimistic update
      members?.forEach((member) => {
        // @ts-expect-error
        commit(UsersMutationTypes.ADD_USER, member);
      });
      commit(MutationTypes.SET_CHAT_DATA, { chatId, rawChat });

      const appliedMessageId =
        rawChat.lastMessage?.id ?? createMessageId(chatId, userId);
      const uploadedAttachments = await uploadAllFilesAsync({
        attachments,
        chatId,
        messageId: appliedMessageId,
        firebaseIdToken,
      });

      // here we still need to await the response, otherwise snapshot callback will not connect to firebase due to insufficient read permissions
      await createChatBeAsync({
        memberIds,
        firebaseIdToken,
        messageText,
        chatId,
        messageId: appliedMessageId,
        messageType,
        attachments: uploadedAttachments,
      });
      commit(MutationTypes.SET_CHAT_UNSUBSCRIBE, {
        chatId,
        unsubscribe: getChatUnsubscribeById(chatId),
      });
      return chatId;
    } catch (e) {
      console.log(e);
    }
  },
  async [ChatsActionTypes.DELETE_CHAT](
    { dispatch, commit, rootState },
    { chatId, disableSnackbar },
  ) {
    try {
      const firebaseIdToken = rootState.auth.firebaseIdToken;
      if (!chatId || !firebaseIdToken) return;
      commit(ChatsMutationTypes.REMOVE_CHAT, { chatId });
      await deleteChatBeAsync({ chatId, firebaseIdToken });

      if (disableSnackbar) {
        return;
      }
      dispatch(ActionTypes.MOUNT_SNACKBAR, {
        title: "snackbar.notifications.theChatWasDeleted.title",
      });
    } catch (e) {
      console.log(e);
    }
  },
  async [ChatsActionTypes.UPDATE_GROUP_CHAT](
    { rootState },
    { chatId, name, picture },
  ) {
    try {
      const firebaseIdToken = rootState.auth.firebaseIdToken;
      if (!chatId || !firebaseIdToken) return;

      const update: {
        chatId: string;
        firebaseIdToken: string;
        name?: string;
        picture?: string;
      } = {
        chatId,
        firebaseIdToken,
      };

      if (name) update.name = name;
      if (picture) update.picture = picture;

      await updateChatBeAsync(update);
    } catch (e) {
      console.log(e);
    }
  },
  async [ChatsActionTypes.UPDATE_GROUP_CHAT_MEMBERS](
    { rootState },
    { chatId, addMemberIds, removeMemberIds },
  ) {
    try {
      const firebaseIdToken = rootState.auth.firebaseIdToken;
      if (!chatId || !firebaseIdToken) return;

      await updateChatMembersBeAsync({
        chatId,
        firebaseIdToken,
        addMemberIds,
        removeMemberIds,
      });
    } catch (e) {
      console.log(e);
    }
  },
  async [ChatsActionTypes.LEAVE_GROUP_CHAT](
    { rootState, dispatch },
    { chatId },
  ) {
    try {
      const firebaseIdToken = rootState.auth.firebaseIdToken;
      if (!chatId || !firebaseIdToken) return;
      await leaveChatBeAsync({ chatId, firebaseIdToken });

      dispatch(ActionTypes.MOUNT_SNACKBAR, {
        title: "snackbar.notifications.youHaveLeftTheGroupConversation.title",
      });
    } catch (e) {
      console.log(e);
    }
  },
  async [ChatsActionTypes.SEND_MESSAGE](
    { commit, rootState },
    { chatId, message, messageType, modifyingMessageId },
  ) {
    // only perform the optimistic update for new messages, not for messages that are being modified
    if (!modifyingMessageId) {
      commit(ChatsMutationTypes.SET_UPLOADING_MESSAGE, {
        chatId,
        message,
      });
    }
    try {
      const { firebaseIdToken, refreshToken, accessToken } = rootState.auth;
      const { attachments, replyToMessage } = message;
      const senderId = rootState.auth?.profile?.id;
      const messageText = getIsRawMessageTextType(message)
        ? message.messageText
        : "";
      if (
        !senderId ||
        !chatId ||
        !firebaseIdToken ||
        !refreshToken ||
        !accessToken
      )
        return;

      const appliedMessageId = message?.id ?? createMessageId(chatId, senderId);
      const uploadedAttachments = await uploadAllFilesAsync({
        attachments,
        chatId,
        messageId: appliedMessageId,
        firebaseIdToken,
      });

      if (messageText.length === 0 && uploadedAttachments.length === 0) return;

      await sendMessageBeAsync({
        bearerToken: accessToken,
        chatId,
        messageId: appliedMessageId,
        messageText,
        attachments: uploadedAttachments,
        messageType,
        replyToMessageId: replyToMessage?.id,
        modifyingMessageId,
      });
    } catch (e) {
      commit(ChatsMutationTypes.REMOVE_UPLOADING_MESSAGE, {
        chatId,
        messageId: message.id,
      });
      console.log(e);
      throw e;
    }
  },
  async [ChatsActionTypes.FORWARD_MESSAGE](
    { commit, rootState },
    { chatIds, message },
  ) {
    const { firebaseIdToken, accessToken } = rootState.auth;
    const { attachments } = message;
    const senderId = rootState.auth?.profile?.id;
    const messageText = getIsRawMessageTextType(message)
      ? message.messageText
      : "";
    if (!senderId || !firebaseIdToken || !accessToken) return;

    const messageIdentifiers = chatIds.map((chatId) => ({
      chatId,
      messageId: createMessageId(chatId, senderId),
    }));

    const uploadAttachmentsPromises = messageIdentifiers.map(
      async ({ chatId, messageId }) => {
        commit(ChatsMutationTypes.SET_UPLOADING_MESSAGE, {
          chatId,
          message: {
            ...message,
            id: messageId,
          },
        });

        const uploadedAttachments = await uploadAllFilesAsync({
          attachments,
          chatId,
          messageId,
          firebaseIdToken,
        });
        const item: IForwardMessageItem = {
          attachments: uploadedAttachments,
          chatId,
          messageId,
        };

        return item;
      },
    );

    const items = await getPromisesSuccessValues(uploadAttachmentsPromises);
    const failedUploads = getDifferenceArray(
      chatIds,
      items.map(({ chatId }) => chatId),
    );
    failedUploads.forEach((chatId) => {
      const messageId = messageIdentifiers.find(
        (item) => item.chatId === chatId,
      )?.messageId;
      if (!messageId) {
        return;
      }
      commit(ChatsMutationTypes.REMOVE_UPLOADING_MESSAGE, {
        chatId,
        messageId,
      });
    });

    try {
      const failedIdentificators = await forwardMessageBeAsync({
        bearerToken: accessToken,
        items,
        messageText,
        messageType: message.messageType ?? MessageType.Text,
      });
      failedIdentificators.forEach(({ chatId, messageId }) => {
        commit(ChatsMutationTypes.REMOVE_UPLOADING_MESSAGE, {
          chatId,
          messageId,
        });
      });
    } catch (e) {
      messageIdentifiers.forEach(({ chatId, messageId }) => {
        commit(ChatsMutationTypes.REMOVE_UPLOADING_MESSAGE, {
          chatId,
          messageId,
        });
      });
    }
  },
  async [ChatsActionTypes.MARK_MESSAGE_AS_READ](
    { state, rootState },
    { messageId, chatId },
  ) {
    const firebaseIdToken = rootState.auth.firebaseIdToken;
    const authId = rootState.auth.profile?.id;
    if (!messageId || !firebaseIdToken || !authId) return;
    const message = state.chats?.[chatId].messages?.[messageId];

    if (getIsRawMessageCallType(message)) {
      return;
    }

    try {
      if (!message || message?.senderId === authId) {
        return;
      }

      const isOneToOneTextType =
        getIsRawMessageTextType(message) &&
        getIsOneToOneRawTextMessage(message, chatId);
      const isOneToOneAudioType =
        getIsRawMessageAudioType(message) &&
        getIsOneToOneRawAudioMessage(message, chatId);
      const isOneToOneReadable =
        getIsRawMessageFriendRequestType(message) ||
        isOneToOneTextType ||
        isOneToOneAudioType;

      if (isOneToOneReadable) {
        await markOneToOneMessageAsRead({ message, firebaseIdToken });
        return;
      }

      if (message.readBy?.includes(authId)) {
        return;
      }

      await markMessageAsReadBeAsync({ messageId, firebaseIdToken });
    } catch (e) {
      console.log(e);
    }
  },
  async [ChatsActionTypes.DELETE_MESSAGES]({ rootState }, { messageIds }) {
    try {
      const firebaseIdToken = rootState.auth.firebaseIdToken;
      if (!messageIds || messageIds?.length < 1 || !firebaseIdToken) return;
      await deleteMessagesBeAsync({ messageIds, firebaseIdToken });
    } catch (e) {
      console.log(e);
    }
  },
  async [ChatsActionTypes.UPDATE_ATTACHMENT_UPLOAD_PROGRESS](
    _,
    { chatId, messageId, attachmentIdx, progress },
  ) {
    updateAttachmentProgressBeAsync({
      chatId,
      messageId,
      attachmentIdx,
      progress,
    });
  },

  async [ChatsActionTypes.UPDATE_MESSAGE_DATA](
    { getters, commit, rootState },
    { change, chatId },
  ) {
    const initialLoadCompleted = getters.GET_CHATS_INITIAL_LOAD;
    if (!initialLoadCompleted) {
      return;
    }
    const rawMessage = change.doc.data() as RawMessage;
    const changeType = change.type as FirestoreDocumentChangeType;
    const chats = rootState.chats.chats;
    const chat = chats[chatId];
    if (!chat?.rawChat) {
      const chatRef = await getChatRef(chatId).get();
      const rawChat = chatRef.data() as RawChat;
      commit(MutationTypes.SET_CHAT_DATA, { chatId, rawChat });
      commit(MutationTypes.SET_MESSAGE_DATA, {
        changeType,
        rawMessage,
        chatId,
      });
      return;
    }

    commit(MutationTypes.SET_MESSAGE_DATA, {
      changeType,
      rawMessage,
      chatId,
    });
  },
  async [ChatsActionTypes.DELETE_NOT_UPLOADED_ATTACHMENTS](
    { getters },
    { chatId, messages },
  ) {
    const initialLoadCompleted = getters.GET_CHATS_INITIAL_LOAD;
    if (!initialLoadCompleted) {
      return;
    }

    const args = getAttachmentsForDeletionArgs({
      chatId,
      messages,
    });

    const promises = args.map(async (arg) => {
      await deleteAttachmentBeAsync(arg);
    });

    await Promise.allSettled(promises);
  },
  async [ChatsActionTypes.DELETE_ATTACHMENT]({ getters }, arg) {
    const initialLoadCompleted = getters.GET_CHATS_INITIAL_LOAD;
    if (!initialLoadCompleted) {
      return;
    }

    await deleteAttachmentBeAsync(arg);
  },
  async [ChatsActionTypes.UPDATE_ATTACHMENT_SOURCES](
    { commit },
    { chatId, messageId, attachmentIdx, accessToken, videoCdnId },
  ) {
    try {
      const videoData = await getVideoDetailsBeAsync({
        accessToken,
        videoCdnId,
      });
      const {
        playback: { hls },
        thumbnail,
      } = videoData.result;

      const updateArgs: IUpdateAttachmentSourcesArgs = {
        chatId,
        messageId,
        attachmentIdx,
        thumbnailUrl: thumbnail,
        url: hls,
      };

      // we do not need to wait for this request to succeed, thus there is no await
      updateAttachmentSourcesBeAsync(updateArgs);
      return { url: hls, thumbnailUrl: thumbnail };
    } catch (error) {
      commit(ChatsMutationTypes.UPDATE_MESSAGE_DISPLAY_STATUS, {
        chatId,
        messageId,
        attachmentIdx,
        displayStatus: VideoDisplayStatus.Error,
      });
    }
  },
  async [ChatsActionTypes.UPDATE_CHAT_DATA](
    { getters, commit },
    { chatId, snapshot },
  ) {
    const initialLoadCompleted = getters.GET_CHATS_INITIAL_LOAD;
    if (!initialLoadCompleted) {
      return;
    }
    const data = snapshot.data();
    if (!data) return;

    const rawChat = data as RawChat;

    commit(MutationTypes.SET_CHAT_DATA, { chatId, rawChat });
  },

  async [ChatsActionTypes.ADD_CHAT_DATA_FROM_QUERY](
    { getters, commit },
    { rawChat },
  ) {
    const initialLoadCompleted = getters.GET_CHATS_INITIAL_LOAD;
    if (!initialLoadCompleted) {
      return;
    }
    const chatId = rawChat.id;
    commit(MutationTypes.SET_CHAT_UNSUBSCRIBE, {
      chatId,
      unsubscribe: getChatUnsubscribeById(chatId),
    });
    commit(MutationTypes.SET_CHAT_DATA, { chatId, rawChat });
  },
  async [ChatsActionTypes.SEND_MESSAGE_REACTION]({ rootState }, args) {
    const { chatId, reaction } = args;
    const userId = String(rootState.auth.profile.id);
    const accessToken = rootState.auth.accessToken;
    if (!accessToken) {
      return;
    }

    const rawMessage =
      rootState.chats.chats[args.chatId]?.messages?.[args.messageId];
    if (getIsRawMessageAutomaticType(rawMessage)) {
      return;
    }

    try {
      const newReactionItems = {
        ...rawMessage.reactions?.items,
        [userId]: reaction,
      };
      // optimistic update
      optimisticUpdateReactions({
        rawMessage: {
          ...rawMessage,
          reactions: getUpdatedReactionItems(newReactionItems),
        },
        chatId,
      });

      await sendMessageReaction({ accessToken, ...args });
    } catch {
      // if the request failes, we fallback to the previous state
      optimisticUpdateReactions({
        rawMessage: {
          ...rawMessage,
        },
        chatId,
      });
    }
  },
  async [ChatsActionTypes.REMOVE_MESSAGE_REACTION]({ rootState }, args) {
    const { chatId } = args;
    const userId = String(rootState.auth.profile.id);
    const accessToken = rootState.auth.accessToken;
    if (!accessToken) {
      return;
    }

    const rawMessage =
      rootState.chats.chats[args.chatId]?.messages?.[args.messageId];
    if (getIsRawMessageAutomaticType(rawMessage)) {
      return;
    }

    try {
      const newReactionItems = {
        ...rawMessage.reactions?.items,
      };
      if (newReactionItems[userId]) {
        delete newReactionItems[userId];
      }

      // optimistic update
      optimisticUpdateReactions({
        rawMessage: {
          ...rawMessage,
          reactions: getUpdatedReactionItems(newReactionItems),
        },
        chatId,
      });

      await removeMessageReaction({ accessToken, ...args });
    } catch {
      // if the request failes, we fallback to the previous state
      optimisticUpdateReactions({
        rawMessage: {
          ...rawMessage,
        },
        chatId,
      });
    }
  },
};
