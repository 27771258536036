export enum IconType {
  ADD_USER = "addUser",
  ARROW = "arrow",
  BACK = "back",
  BACK_GRAY = "backGray",
  BACK_CIRCLE = "backCircle",
  CARD = "card",
  FORWARD = "forward",
  FORWARD_RED = "forwardRed",
  FORWARD_WHITE = "forwardWhite",
  BELL = "bell",
  BLOCK = "block",
  BLOCK_GRAY = "blockGray",
  REPORT = "report",
  REPORT_GRAY = "reportGray",
  BULL = "bull",
  CALENDAR = "calendar",
  CALLS = "calls",
  CALLS_BLACK = "callsBlack",
  CAM = "cam",
  CONTROL_CAMERA_ACTIVE = "controlCameraActive",
  CONTROL_CAMERA_INACTIVE = "controlCameraInactive",
  CONTROL_MICROPHONE_ACTIVE = "controlMicrophoneActive",
  CONTROL_MICROPHONE_INACTIVE = "controlMicrophoneInactive",
  CONTROL_HANG_UP = "controlHangUp",
  CONTROL_HANG_UP_HOVER = "controlHangUpHover",
  CONTROL_OPTIONS_INACTIVE = "controlOptionsInactive",
  CHECKBOX_OFF = "checkboxOff",
  CHECKBOX_ON = "checkboxOn",
  CHECKBOX_ON_GRAY = "checkboxOnGray",
  CHECKMARK = "checkmark",
  CLIP = "clip",
  COG = "cog",
  CROSS = "cross",
  CROSS_SMALL = "crossSmall",
  CROSS_GREY_CIRCLE = "crossGreyCircle",
  CROSS_WHITE = "crossWhite",
  DEFAULT_GROUP = "defaultGroup",
  DEFAULT_SINGLE = "defaultSingle",
  DELIVERED = "delivered",
  DIAMOND = "diamond",
  DROPDOWN_ARROW = "dropdownArrow",
  DOTS = "dots",
  DOTS_BLACK = "dotsBlack",
  EDIT = "edit",
  EMOJI = "emoji",
  EMOJI_GRAY = "emojiGray",
  EMOJI_BLACK = "emojiBlack",
  ERROR = "error",
  ERROR_RED = "errorRed",
  FILE_GRAY = "fileGray",
  FLAG = "flag",
  FACE_ID = "faceId",
  GALLERY_PLUS = "galleryPlus",
  GIF_GRAY = "gifGray",
  GIF_BLACK = "gifBlack",
  GROUP = "group",
  GROUPS = "groups",
  HOME = "home",
  INFO = "info",
  LANGUAGE = "language",
  LOGO = "logo",
  LEAVE = "leave",
  LEAVE_RED = "leaveRed",
  LOGOUT = "logout",
  MAGNIFY = "magnify",
  MAGNIFY_INPUT = "magnifyInput",
  MESSAGE = "message",
  MESSAGE_DOUBLE = "messageDouble",
  MESSAGE_DOUBLE_BLACK = "messageDoubleBlack",
  MESSAGES = "messages",
  MIC = "mic",
  NEW_MESSAGE = "newMessage",
  NEW_MESSAGE_ORANGE = "newMessageOrange",
  NEW_USER = "newUser",
  NO_NOTIFY = "noNotify",
  NO_NOTIFY_BLACK = "noNotifyBlack",
  NOTIFICATIONS = "notifications",
  PHONE = "phone",
  PAUSE_WHITE = "pauseWhite",
  PAUSE_BLACK = "pauseBlack",
  PHONE_GRAY = "phoneGray",
  PHONE_RED = "phoneRed",
  PHONE_SMART = "phoneSmart",
  PLAY_WHITE = "playWhite",
  PLAY_BLACK = "playBlack",
  PLUS = "plus",
  REPLY_THIN = "replyThin",
  RING = "ring",
  RING_BLACK = "ringBlack",
  RENAME = "rename",
  REMOVE_MESSAGE = "removeMessage",
  FORWARD_MESSAGE = "forwardMessage",
  SAMPLES = "samples",
  SEEN = "seen",
  SEND = "send",
  SENT = "sent",
  SILENT = "silent",
  SPINNER = "spinner",
  STAR_WHITE = "starWhite",
  BADGE_ENABLED = "badgeEnabled",
  BADGE_DISABLED = "badgeDisabled",
  TRASH = "trash",
  TRASH_RED = "trashRed",
  TRASH_GRAY = "trashGray",
  USER = "user",
  FILE = "file",
  DEFAULT_GROUP_GRAY = "defaultGroupGray",
  DEFAULT_SINGLE_GRAY = "defaultSingleGray",
  INCOMING_COMPLETED_CALL = "incomingCompletedCall",
  INCOMING_CANCELLED_CALL = "incomingCancelledCall",
  OUTGOING_COMPLETED_CALL = "outgoingCompletedCall",
  OUTGOING_COMPLETED_CALL_PREVIEW = "outgoingCompletedCallPreview",
  OUTGOING_CANCELLED_CALL = "outgoingCancelledCall",
  OUTGOING_CANCELLED_CALL_PREVIEW = "outgoingCancelledCallPreview",
  ONGOING_CALL_PREVIEW = "ongoingCallPreview",
  INCOMING_CALL = "incomingCall",
  COPY = "copy",
  REPLY = "reply",
  REPLY_GRAY = "replyGray",
}
