import DOMPurify from "dompurify";
import linkifyHtml from "linkify-html";

const LINKIFY_OPTIONS = {
  defaultProtocol: "https",
  target: "_blank",
};

const DOM_PURIFY_CONFIG = {
  ALLOWED_TAGS: ["a"],
  ALLOWED_ATTR: ["href", "target"],
};

export const parseMessageText = (text?: string): string =>
  text
    ? DOMPurify.sanitize(linkifyHtml(text, LINKIFY_OPTIONS), DOM_PURIFY_CONFIG)
    : "";
