<template>
  <SettingsDetails
    :handle-redirect="redirect"
    :title="$t('settings.chat.showUsers.subtitle')"
    :handle-action="handleAction"
    :action-title="$t('settings.chat.showUsers.addUser')"
  >
    <div class="innerContainer">
      <div class="usersList">
        <div v-for="(user, index) in state.members" :key="user.id" class="user">
          <div v-if="index !== 0" class="divider" />
          <div class="userContainer">
            <ProfileImage
              :id="user.id"
              :image="user.picture"
              :size="48"
              class="profileImg"
            />
            <div class="userName">
              {{ user.name }}
            </div>
            <Icon
              :icon="IconType.TRASH"
              class="deleteIcon"
              @click="removeMember(user.id, user)"
            />
          </div>
        </div>
      </div>
      <div class="buttons">
        <GenericButton
          :variant="GenericButtonVariantEnum.Primary"
          text="settings.chat.showUsers.options.save"
          @click="saveChanges"
        />
        <GenericButton
          :variant="GenericButtonVariantEnum.Secondary"
          text="settings.chat.showUsers.options.cancel"
          @click="redirect"
        />
      </div>
    </div>
    <InfoModal
      v-if="state.isModalShown"
      title="settings.chat.showUsers.redirectModal.title"
      label="settings.chat.showUsers.redirectModal.label"
      btn-text="settings.chat.showUsers.redirectModal.save"
      cancel-btn-text="settings.chat.showUsers.redirectModal.cancel"
      :save-action="saveChanges"
      :cancel-action="redirect"
      :close-action="closeModal"
    />
  </SettingsDetails>
</template>
<script setup lang="ts">
import SettingsDetails from "@/components/settings/SettingsDetails/SettingsDetails.vue";
import { useGetChatSettingsCommonProperties } from "../useGetChatSettingsCommonProperties";
import { User } from "@/store/users/state";
import Icon from "@/components/icons/Icon/Icon.vue";
import ProfileImage from "@/components/images/ProfileImage.vue";
import { IconType } from "@/types/icons";
import GenericButton from "@/components/buttons/GenericButton.vue";
import InfoModal from "@/components/modals/InfoModal.vue";
import { reactive, computed } from "vue";
import { store } from "@/store";
import { AllActionTypes } from "@/store/actionTypes";
import { GenericButtonVariantEnum } from "@/types/button";
const { selectedChatId, route, router, getters } =
  useGetChatSettingsCommonProperties();

const chatId = computed<string>(
  () => selectedChatId.value ?? (route.params.chat_id as string),
);

const chatDetail = computed(() =>
  getters.GET_CONVERTED_CHAT_DETAIL(chatId.value),
);

const membersList = computed<User[] | undefined>(() => {
  return chatDetail.value.members;
});

interface StateProps {
  members: User[];
  membersForRemove: User[];
  isModalShown: boolean;
}

const state: StateProps = reactive({
  members: [...(membersList.value || [])],
  membersForRemove: [],
  isModalShown: false,
});

const removeMember = (id: number, member: User) => {
  state.membersForRemove.push(member);
  state.members = state.members.filter((obj) => obj.id !== id);
};

const saveChanges = () => {
  const removeMemberIds = state.membersForRemove.map((m) => m.id).map(String);

  store.dispatch(AllActionTypes.UPDATE_GROUP_CHAT_MEMBERS, {
    chatId: chatId.value,
    removeMemberIds,
  });
  router.push(`/${chatDetail.value.id}`);
};

const redirect = () => {
  if (state.membersForRemove.length === 0 || state.isModalShown) {
    return router.replace(`/settings/chat/${chatDetail.value.id}`);
  }
  state.isModalShown = true;
};
const closeModal = () => {
  state.isModalShown = false;
};

const handleAction = async () => {
  await router.replace(`/settings/chat/add_users_to_group/${chatId.value}`);
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/settings/settingsShowUsers.scss";
</style>
