<template>
  <div
    class="message-content-container"
    :class="{
      isMine,
      isFriendRequestType,
      isPendingFriendRequest,
    }"
  >
    <BackdropModal :enabled="hasShadow">
      <div
        :id="elementId"
        ref="messageContainer"
        class="mess-content"
        :class="{
          isFixed: hasShadow,
          isFriendRequestType,
          isMine,
          hasReactions,
        }"
        :style="contentStyle"
      >
        <TransitionGroup v-if="isMobile" name="pop">
          <MessageEmojiReactionsPanel
            v-if="isControlsActive"
            :message-id="message.id"
            :is-mine="isMine"
            @close-panel="closeControls"
          />
          <div v-if="isControlsActive" class="message-reaction-controls">
            <div
              v-for="control in controls"
              :key="control.icon"
              class="reaction-control-item"
              @mousedown.left="control.action"
            >
              <Icon class="icon" :icon="control.icon" />
              <div class="reaction-control-right">
                <span class="reaction-control-text">{{ control.text }}</span>
                <Icon class="icon" :icon="IconType.FORWARD" />
              </div>
            </div>
          </div>
        </TransitionGroup>
        <MessageWithReactions
          v-bind="messageWithReactionsProps"
          @open-modal="openReactionsModal"
        >
          <slot />
        </MessageWithReactions>
      </div>
    </BackdropModal>
    <div
      v-if="hasShadow && isMobile"
      class="mess-content"
      :class="{ isFriendRequestType }"
    >
      <MessageWithReactions v-bind="messageWithReactionsProps">
        <slot />
      </MessageWithReactions>
    </div>
    <slot name="modal" />
    <slot name="checkboxSelectMessage" />
  </div>
</template>

<script setup lang="ts">
import {
  getIsAutomaticMessageType,
  getIsMessageFriendRequestType,
  getIsMessageTextType,
  getIsMyMessage,
} from "@/utils/message";
import { FriendRequestStatus, Message } from "@/store/chats/types";
import { useComputedValue, useGetMessageHandlers } from "@/composables";
import { GetterTypes } from "@/store";
import { computed, onUnmounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { ChatsMutationTypes } from "@/store/chats";
import { IconType } from "@/types/icons";
import Icon from "@/components/icons/Icon/Icon.vue";
import { copyTextToClipboard } from "@/utils/app";
import BackdropModal from "@/components/modals/BackdropModal/BackdropModal.vue";
import MessageWithReactions from "../MessageWithReactions/MessageWithReactions.vue";
import MessageEmojiReactionsPanel from "../MessageEmojiReactionsPanel/MessageEmojiReactionsPanel.vue";
import { LayoutTypes } from "@/store/app/state";
import { convertNumberToPx } from "@/utils/modifiers";
import { useI18n } from "vue-i18n";

interface ChatMessageReactionsProps {
  message: Message;
  isActive: boolean;
  hasShadow: boolean;
  isModalOpen: boolean;
  modalId: string;
  elementId: string;
  position: { top: number; left: number; width: number };
  isTouchEndEnabled: boolean;
}

interface Emits {
  (e: "hideShadow"): void;
  (e: "openModal"): void;
}

const emit = defineEmits<Emits>();

const {
  handleReplyToMessage,
  handleModifyMessage,
  handleRemoveMessage,
  openForwardMessageDialog,
} = useGetMessageHandlers();
const { commit } = useStore();
const { t } = useI18n();
const props = defineProps<ChatMessageReactionsProps>();
const authId = useComputedValue<number>(GetterTypes.GET_AUTH_ID);
const isMine = computed(() => getIsMyMessage(props.message, authId.value));
const messageContainer = ref<HTMLDivElement>();
const isFriendRequestType = computed(() =>
  getIsMessageFriendRequestType(props.message),
);
const isPendingFriendRequest = computed(
  () =>
    getIsMessageFriendRequestType(props.message) &&
    props.message.status === FriendRequestStatus.Pending &&
    !props.message.isMine,
);
const isTextMessage = computed(() => getIsMessageTextType(props.message));
const isControlsActive = computed(() => props.isActive && !props.isModalOpen);
const messageReactionId = useComputedValue<string | null>(
  GetterTypes.GET_MESSAGE_REACTION_ID,
);
const isReacting = computed(() => !!messageReactionId.value);
const messageWithReactionsProps = computed(() => ({
  message: props.message,
  isMine: isMine.value,
  elementId: props.elementId,
}));
const layoutType = useComputedValue<LayoutTypes>(GetterTypes.GET_LAYOUT_TYPE);
const isMobile = computed(() => layoutType.value === LayoutTypes.MOBILE);
const hasReactions = computed(
  () =>
    !getIsAutomaticMessageType(props.message) &&
    props.message.reactions?.visibleItems?.length,
);

const contentStyle = computed(() => {
  if (!isControlsActive.value) {
    return;
  }
  const { top, left, width } = props.position;
  return {
    top: convertNumberToPx(top),
    left: convertNumberToPx(left),
    width: width ? convertNumberToPx(width) : undefined,
  };
});

watch(isReacting, (newVal) => {
  if (!newVal || !props.isActive) {
    return;
  }

  window.addEventListener("mousedown", onClickOutside, { capture: true });
});

onUnmounted(() => {
  clearClickOutsideEventListener();
});

const clearClickOutsideEventListener = () => {
  window.removeEventListener("mousedown", onClickOutside, { capture: true });
};
const getIsContainingElement = (
  element: HTMLDivElement,
  wrapper: HTMLElement | undefined | null,
) => Boolean(wrapper === element || wrapper?.contains(element));

const onClickOutside = (e: MouseEvent) => {
  const target = e.target as HTMLDivElement;
  // this is due to unability to pass container reference to the modal
  const modal = document.getElementById(props.modalId);

  if (
    getIsContainingElement(target, messageContainer.value) ||
    getIsContainingElement(target, modal)
  ) {
    return;
  }
  closeControls();
};

const clearReactionId = () => {
  commit(ChatsMutationTypes.SET_MESSAGE_REACTION_ID, {
    messageId: null,
  });
};

const closeControls = () => {
  if (!props.isTouchEndEnabled) {
    return;
  }
  clearReactionId();
  clearClickOutsideEventListener();
  emit("hideShadow");
};

const copyMessageToClipboard = () => {
  if (!getIsMessageTextType(props.message) || !props.isTouchEndEnabled) {
    return;
  }
  closeControls();
  copyTextToClipboard(props.message.messageText);
};

const openReactionsModal = () => {
  if (isControlsActive.value) {
    return;
  }
  emit("openModal");
};

const replyToMessage = () => {
  handleReplyToMessage(props.message);
  closeControls();
};

const modifyMessage = () => {
  handleModifyMessage(props.message);
  closeControls();
};

const removeMessage = () => {
  handleRemoveMessage(props.message);
  closeControls();
};

const forwardMessage = () => {
  openForwardMessageDialog(props.message);
  closeControls();
};

const controls = computed(() => {
  const result = [
    {
      icon: IconType.REPLY_THIN,
      text: t("chat.chatContainer.messages.controls.reply"),
      action: replyToMessage,
    },
  ];
  if (isTextMessage.value) {
    result.push({
      icon: IconType.COPY,
      text: t("chat.chatContainer.messages.controls.copy"),
      action: copyMessageToClipboard,
    });
  }
  if (
    getIsMessageTextType(props.message) &&
    isMine.value &&
    !props.message.isForwarded
  ) {
    result.push({
      icon: IconType.EDIT,
      text: t("chat.chatContainer.messages.controls.modify"),
      action: modifyMessage,
    });
  }

  if (!getIsAutomaticMessageType(props.message) && isMine.value) {
    result.push({
      icon: IconType.REMOVE_MESSAGE,
      text: t("chat.chatContainer.messages.controls.delete"),
      action: removeMessage,
    });
  }
  if (!getIsAutomaticMessageType(props.message)) {
    result.push({
      icon: IconType.FORWARD_MESSAGE,
      text: t("chat.chatContainer.messages.controls.forward"),
      action: forwardMessage,
    });
  }
  return result;
});
</script>

<style lang="scss">
@import "./ChatMessageReactions.scss";
</style>
