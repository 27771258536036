import { NonAutomaticMessage } from "@/store/chats/types";
import { ComposerTranslation } from "vue-i18n";
import { getIsMessageTextType } from "./getIsMessageTextType";

interface GetMessageReplyTitleArgs {
  message: NonAutomaticMessage;
  authId: number;
  isMine: boolean;
  t: ComposerTranslation;
}

export const getMessageReplyTitle = ({
  message,
  authId,
  isMine,
  t,
}: GetMessageReplyTitleArgs) => {
  const replyToSenderName = message?.replyToMessage?.senderName;
  if (!replyToSenderName) {
    return "";
  }

  if (isMine) {
    return t("chat.chatContainer.messages.replies.youAreReplyingTo", {
      replyToSenderName,
    });
  }
  if (message?.replyToMessage?.senderId === authId) {
    return t("chat.chatContainer.messages.replies.senderIsReplyingToYou", {
      senderName: message.senderName,
    });
  }
  return t("chat.chatContainer.messages.replies.senderIsReplyingTo", {
    senderName: message.senderName,
    replyToSenderName: replyToSenderName,
  });
};
