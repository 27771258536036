export const chatApiConfig = {
  url: process.env.VUE_APP_CHAT_API_URL as string,
  endpoints: {
    video: {
      upload: "/video/upload",
      detail: (videoId: string) => `/video/${videoId}`,
    },
    message: {
      index: "/message",
      uploadFile: "/message/upload-file",
      markAsRead: (messageId: string) => `message/${messageId}/mark-as-read`,
      updateAttachmentUploadProgress:
        "/message/update-attachment-upload-progress",
      updateAttachmentSources: "/message/update-attachment-sources",
      deleteAttachment: "/message/delete-attachment",
      addReaction: "/message/add-reaction",
      removeReaction: "/message/remove-reaction",
      forwardMessage: "/message/forward-message",
    },
    call: {
      prompt: "/videocall/callprompt",
      reject: "/videocall/reject",
    },
  },
} as const;
