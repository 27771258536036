import { User } from "@/store/users/state";
import { ref } from "vue";

interface UseGetDesktopChatSelectPropertiesArgs {
  defaultMembers: User[];
  isAddMembersShown?: boolean;
}

export const useGetDesktopChatSelectProperties = ({
  defaultMembers,
  isAddMembersShown: defaultIsAddMembersShown = false,
}: UseGetDesktopChatSelectPropertiesArgs) => {
  const members = ref<User[]>(defaultMembers);
  const isAddMembersShown = ref(defaultIsAddMembersShown);

  const addMember = (member: User) => {
    if (members.value.find((m) => m.id === member.id)) {
      return;
    }

    members.value.push(member);
  };

  const removeMember = (member: User) => {
    members.value = members.value.filter((m) => m.id !== member.id);
  };

  const showAddMembers = () => {
    isAddMembersShown.value = true;
  };

  return {
    addMember,
    removeMember,
    showAddMembers,
    members,
    isAddMembersShown,
  };
};
