<template>
  <ListItemsSelectNavigationContainer
    :navigation-title="$t('settings.changeLanguage')"
    :lists-array="[settingsChangeLanguageMenuItems]"
  />
</template>
<script setup lang="ts">
import ListItemsSelectNavigationContainer from "@/components/layouts/ListItemsSelectNavigationContainer/ListItemsSelectNavigationContainer.vue";
import { IListItem } from "@/types/list";
import { computed } from "vue";
import { getSettingsChangeLanguageMenuItems } from "../../utils/settings";

const settingsChangeLanguageMenuItems = computed<IListItem[]>(() => {
  return getSettingsChangeLanguageMenuItems();
});
</script>
<style lang="scss" scoped>
@import "src/assets/scss/settings/settingsPersonalDetails";
</style>
