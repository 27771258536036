import { config } from "@/config";
import { chatApiAxios } from "@/plugins";
import { HttpRequestMethodEnum } from "@/types/api";
import { ISendMessageReactionArgs } from "@/types/chat";
import { getCommonJsonHeaders } from "@/utils/api";

interface SendMessageReactionArgs extends ISendMessageReactionArgs {
  accessToken: string;
}

export const sendMessageReaction = async ({
  chatId,
  messageId,
  accessToken,
  reaction,
}: SendMessageReactionArgs) => {
  const res = await chatApiAxios(
    config.api.chatApi.endpoints.message.addReaction,
    {
      method: HttpRequestMethodEnum.POST,
      headers: getCommonJsonHeaders(accessToken),
      data: {
        chatId,
        messageId,
        reaction,
      },
    },
  );

  return res.data;
};
