<template>
  <SelectChatWrapper
    :lists-container-height="listsContainerHeight"
    :lists="lists"
    :is-recommendations-visible="isRecommendationsVisible"
    :selected-members="selectedGroupMembers"
    :title="$t('chat.chatSidebar.NewChatPreview.title')"
    :confirm-title="$t('settings.chat.createGroupChat.createGroup')"
    @add-selected="handleAddGroupMember"
    @remove-selected="handleRemoveGroupMember"
    @redirect="handleRedirect"
    @confirm="handleCreateChat"
  />
</template>
<script setup lang="ts">
import { useGetNewChatMobileContainerProperties } from "./useGetNewChatMobileContainerProperties";
import SelectChatWrapper from "../SelectChatWrapper/SelectChatWrapper.vue";

const {
  lists,
  listsContainerHeight,
  handleRedirect,
  selectedGroupMembers,
  handleAddGroupMember,
  handleRemoveGroupMember,
  handleCreateChat,
  isRecommendationsVisible,
} = useGetNewChatMobileContainerProperties();
</script>
