<template>
  <DesktopSelectChatWrapper
    :members="members"
    :item-icon="IconType.CHECKBOX_ON"
    :title="$t('popup.PopupForwardMessage.title')"
    :confirm-title="$t('popup.PopupForwardMessage.confirm')"
    :is-add-members-shown="isAddMembersShown"
    @add-member="addMember"
    @item-action="removeMember"
    @confirm="saveChanges"
  />
</template>

<script setup lang="ts">
import { GetterTypes } from "@/store";
import { IconType } from "@/types/icons";
import DesktopSelectChatWrapper from "../chat/chatContainer/DesktopSelectChatWrapper/DesktopSelectChatWrapper.vue";
import {
  useComputedValue,
  useGetDesktopChatSelectProperties,
  useGetMessageHandlers,
} from "@/composables";
import { createOneToOneChatIdsFromUserIds } from "@/utils/chat";

const authId = useComputedValue<number>(GetterTypes.GET_AUTH_ID);
const { handleSendForwardedMessage, clearForwardedMessage } =
  useGetMessageHandlers();

const { isAddMembersShown, members, addMember, removeMember } =
  useGetDesktopChatSelectProperties({
    // defaultMembers: Object.values(users.value),
    defaultMembers: [],
    isAddMembersShown: true,
  });

const saveChanges = () => {
  const memberIds = members.value.map((m) => m.id);
  const chatIds = createOneToOneChatIdsFromUserIds(memberIds, authId.value);

  handleSendForwardedMessage(chatIds);
  clearForwardedMessage();
};
</script>
