import { isOneToOneChat } from "@/firebase/utils";
import { Chat } from "@/store/chats/types";
import { IListItem } from "@/types/list";
import {
  getChatSettingsGroupChatMenuItems,
  getChatSettingsOneToOneChatMenuItems,
  getDeleteConversationArray,
} from "@/utils/chat";
import { getIsChatOwner } from "@/utils/chat/getIsChatOwner";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export const useGetChatSettingsAllProperties = () => {
  const route = useRoute();
  const { getters } = useStore();
  const chatId = route.params.chat_id as string;
  const chatDetail = computed<Chat>(() =>
    getters.GET_CONVERTED_CHAT_DETAIL(chatId),
  );
  const chatSettingsMenuItems = computed<IListItem[]>(() => {
    if (isOneToOneChat(chatId)) {
      return getChatSettingsOneToOneChatMenuItems(chatId);
    }
    return getChatSettingsGroupChatMenuItems(chatId);
  });
  const deleteConversationArray = computed<IListItem[]>(() => {
    if (!isOneToOneChat(chatId) && !getIsChatOwner(chatId)) {
      return [];
    }
    return getDeleteConversationArray(chatId);
  });
  const partnerName = computed<string | undefined>(() => {
    const partnerName = chatDetail.value?.name;

    return partnerName;
  });

  const chatSettingsAllProperties = computed(() => ({
    navigationTitle: partnerName.value,
    listsArray: [chatSettingsMenuItems.value, deleteConversationArray.value],
  }));

  return { chatSettingsAllProperties };
};
